const styles = {
  cardBodyText: {
    paddingTop: 10,
    fontSize: 16,
  },
  h3Header: {
    color: '#777 !important',
    paddingTop: 10,
  },
  greyTextColor: {
    color: '#777 !important',
  },
  printIcon: {
    fontSize: 32,
    padding: 10,
    cursor: 'pointer',
  },
  pStyle: {
    paddingTop: 10,
    fontSize: 16,
  },
  printedCard: {
    width: '3.5in',
    height: '1.6in',
    backgroundColor: '#fff',
    padding: '20px 0 20px 20px',
    margin: '20px',
    border: '1px solid #ccc',
  },
  printedCardP: {
    margin: 0,
    color: '#333',
  },
  printedQrCodeDiv: {
    float: 'left',
    width: '1.2in',
  },
  printedQrCode: {
    padding: 5,
    border: '1px solid #ccc',
  },
  printedRightContainer: {
    float: 'left',
    padding: 15,
    width: '1.9in',
  },
  printedRightP1: {
    margin: 0,
    color: '#333',
    fontSize: 14,
  },
  printedRightP2: {
    margin: 0,
    color: '#333',
    fontSize: 10,
    lineHeight: 1,
    padding: 5,
    marginTop: 10,
    backgroundColor: '#ffeeba',
  },
  printedFooter: {
    width: '100%',
    paddingTop: 20,
    fontSize: 10,
  },
  printedFooterLeft: {
    float: 'left',
    paddingTop: 5,
  },
  printedFooterRight: {
    float: 'right',
    paddingRight: 20,
  },
};

export default styles;
