import React, { useState, useEffect } from 'react';

import { Navigate } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay-ts';
import HashLoader from 'react-spinners/HashLoader';
import { useShopsContext } from '../../../contexts/ShopsContext';
import GridItem from '../../../components/Grid/Item';
import GridContainer from '../../../components/Grid/Container';

import CustomSnackbarContent from '../../../components/CustomSnackbarContent';

import AddShopForm from '../../../forms/Shop/Add';

import { getShopsAction } from '../../../actions/shop/getShops';

import {
  createShopAction,
  initialState as createShopInitialState,
} from '../../../actions/shop/createShop';

const AddShop = () => {
  const [shopsState, shopsDispatch] = useShopsContext();
  const [createShopStatus, setCreateShopStatus] = useState(
    createShopInitialState
  );

  useEffect(() => {
    getShopsAction(shopsDispatch);
  }, []);

  const onCreateShopRequest = (formValues) => {
    formValues.file =
      formValues.file_upload !== '' ? formValues.file_upload : null;
    createShopAction(formValues, setCreateShopStatus);
  };

  const showErrorMessages = () => {
    if (
      createShopStatus.error.exist &&
      createShopStatus.error.message !== undefined
    ) {
      const errorMessages = createShopStatus.error.message;

      if (typeof errorMessages === 'string') {
        return (
          <div style={{ marginTop: 10 }}>
            <CustomSnackbarContent
              message={errorMessages}
              close
              open
              color="danger"
            />
          </div>
        );
      } else {
        for (var k in errorMessages) {
          return errorMessages[k].map((message, key) => {
            return (
              <div style={{ marginTop: 10 }} key={key}>
                <CustomSnackbarContent
                  message={message}
                  close
                  open
                  color="danger"
                />
              </div>
            );
          });
        }
      }
    }

    return null;
  };

  if (shopsState.list.length !== 0) {
    return <Navigate to="/shop" replace={true} />;
  }

  if (Object.keys(createShopStatus.shop).length !== 0) {
    return <Navigate to="/shop" replace={true} />;
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <LoadingOverlay
            active={createShopStatus.processing}
            spinner={<HashLoader color="#4caf50" />}
          >
            {showErrorMessages()}
            <AddShopForm onSubmit={onCreateShopRequest} />
          </LoadingOverlay>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default AddShop;
