import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import withStyles from '@material-ui/core/styles/withStyles';
import ReCAPTCHA from 'react-google-recaptcha';

import CustomButton from '../../components/CustomButton';
import CustomField from '../../components/CustomField';

import styles from './styles';

import {
  GOOGLE_RECAPTCHA_KEY_PROD,
  GOOGLE_RECAPTCHA_KEY_DEV,
} from '../../config';

const SignUpForm = ({ onSubmit, classes }) => {
  const [showPassword, setShowPassword] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    setValue,
  } = useForm();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onReCaptchaChange = () => {
    setValue('reCaptcha', 'triggered');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CustomField
        name="name"
        control={control}
        errors={errors}
        rules={{
          required: 'Name is required',
        }}
        id="name"
        label="Name"
        type="text"
        autoComplete="off"
      />

      <CustomField
        name="email"
        control={control}
        errors={errors}
        rules={{
          required: 'Email Address is required',
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Invalid email address',
          },
        }}
        id="email"
        label="Email Address"
        type="email"
        autoComplete="off"
      />

      <CustomField
        name="password"
        control={control}
        errors={errors}
        rules={{
          required: 'Password is required',
        }}
        id="password"
        label="Password"
        type="password"
        showPassword={showPassword}
        handleClickShowPassword={handleClickShowPassword}
        autoComplete="new-password"
      />

      <div style={{ margin: 'auto', width: 300, paddingTop: 20 }}>
        <ReCAPTCHA
          sitekey={
            process.env.NODE_ENV !== 'production'
              ? GOOGLE_RECAPTCHA_KEY_DEV
              : GOOGLE_RECAPTCHA_KEY_PROD
          }
          onChange={onReCaptchaChange}
        />

        <input
          type="hidden"
          name="reCaptcha"
          {...register('reCaptcha', { required: true })}
        />
        {errors.reCaptcha && errors.reCaptcha.type === 'required' && (
          <span>This is required</span>
        )}
      </div>

      <CustomButton
        block
        color="success"
        className={classes.submitBtn}
        type="submit"
      >
        Sign Up
      </CustomButton>
    </form>
  );
};

export default withStyles(styles)(SignUpForm);
