import { FirebaseService } from '../../services/Firebase';
import { ShopService } from '../../services/Shop';

import {
  throwExceptionIfErrorExists,
  ServerError,
  getServerError,
} from '../../helpers';

export const initialState = {
  data: {},
  error: {
    exist: false,
    message: '',
    hideContent: false,
    closeMessage: true,
  },
  processing: false,
};

export const getShopAction = async (shopId, dispatch) => {
  dispatch({ ...initialState, processing: true });

  try {
    // get the token from firebase
    const token = await FirebaseService.getUserToken();

    // get shop from API
    const apiResponse = await ShopService.getShop(token, shopId);
    throwExceptionIfErrorExists(apiResponse);

    dispatch({ ...initialState, data: apiResponse.data.data });
  } catch (e) {
    const error = getServerError(e.code, e.message);
    dispatch({
      ...initialState,
      error: {
        exist: true,
        hideContent: error.hideContent,
        errorMessage: error.errorMessage,
        closeMessage: error.closeMessage,
      },
    });
  }
};
