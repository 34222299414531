import React from 'react';
import { NavLink } from 'react-router-dom';
import Box from '@material-ui/core/Box';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import GridItem from '../../components/Grid/Item';
import GridContainer from '../../components/Grid/Container';
import Card from '../../components/Card';
import CardHeader from '../../components/Card/Header';
import CardBody from '../../components/Card/Body';
import CustomButton from '../../components/CustomButton';

import styles from './styles';

const HowItWorks = ({ classes }) => {
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
              <h4>How It Works</h4>
            </CardHeader>
            <CardBody className={classes.cardBody}>
              <Box boxShadow={3} className={classes.box}>
                <h3 className={classes.boxHeader}>Step 1</h3>
                <p>
                  Add your shop and the rewards your customer will be able to
                  redeem by pressing the following button.
                </p>
                <div>
                  <NavLink to="/add-shop">
                    <CustomButton color="success">Add Shop</CustomButton>
                  </NavLink>
                </div>
              </Box>
              <Box boxShadow={3} className={classes.box}>
                <h3 className={classes.boxHeader}>Step 2</h3>
                <p>
                  After adding your shop and the given reward, you will be able
                  to start generating the stamps (QR codes) for your customers.
                </p>
                <p>
                  Check out the page that you can generate the stamps by
                  pressing the following button.
                </p>
                <div>
                  <NavLink to="/generate-stamp">
                    <CustomButton color="success">Generate Stamp</CustomButton>
                  </NavLink>
                </div>
              </Box>
              <Box boxShadow={3} className={classes.box}>
                <h3 className={classes.boxHeader}>Step 3</h3>
                <p>
                  When your customers have collected all the required stamps
                  from you, they will be able to redeem the given reward.
                </p>
                <p>
                  After they send the redeem request from their Stampa mobile
                  application, you will receive a notification message.
                </p>
                <p>
                  In the Redeem Requests page, you can see all the customers
                  redeem requests waiting for you to approve or decline them.
                </p>
                <p>
                  If you approve the request, your customer will receive
                  instantly a notification message from the Stampa application,
                  notifying him you have approved his request and Stampa will
                  take all his collected stamps.
                </p>
                <p>
                  If you decline your customer request, your customer will still
                  receive a notification message from the Stampa application,
                  notifying him you have declined his request but Stampa will
                  not take any of his collected stamps. The client will be able
                  to re-send a redeem request after this.
                </p>
                <p>
                  Check out the Redeem Requests page by pressing the following
                  button.
                </p>
                <div>
                  <NavLink to="/redeem-requests">
                    <CustomButton color="success">Redeem Requests</CustomButton>
                  </NavLink>
                </div>
              </Box>
              <Box>
                <h4>
                  You can always checkout the short video tutorials{' '}
                  <a
                    href="https://stampa.app/video-tutorials.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    here
                  </a>
                </h4>
              </Box>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default withStyles(styles)(HowItWorks);
