import { FirebaseService } from '../../services/Firebase';
import { StampOfferService } from '../../services/StampOffer';

import { throwExceptionIfErrorExists, ServerError } from '../../helpers';

export const initialState = {
  data: {},
  error: {
    exist: false,
    message: '',
  },
  processing: false,
};

export const getStampOfferAction = async (shopId, dispatch) => {
  dispatch({ ...initialState, processing: true });

  try {
    // get the token from firebase
    const token = await FirebaseService.getUserToken();

    // get stamp offer from API
    const apiResponse = await StampOfferService.getStampOffers(token, shopId);
    throwExceptionIfErrorExists(apiResponse);

    dispatch({ ...initialState, data: apiResponse.data.data });
  } catch (e) {
    //TODO: check if error message should be e.errorMessage['description']
    dispatch({
      ...initialState,
      error: {
        exist: true,
        message: ServerError(e.code, e.message),
      },
    });
  }
};
