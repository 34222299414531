import { FirebaseService } from '../../services/Firebase';
import { StampOfferService } from '../../services/StampOffer';

import { throwExceptionIfErrorExists, ServerError } from '../../helpers';

export const initialState = {
  updated: false,
  error: {
    exist: false,
    message: '',
  },
  processing: false,
};

export const updateStampOfferAction = async (
  shopId,
  stampOfferId,
  stampOfferDetails,
  dispatch
) => {
  dispatch({ ...initialState, processing: true });

  try {
    // get the token from firebase
    const token = await FirebaseService.getUserToken();

    // get stamp offer from API
    const apiResponse = await StampOfferService.updateStampOffer(
      token,
      shopId,
      stampOfferId,
      stampOfferDetails
    );
    throwExceptionIfErrorExists(apiResponse);

    dispatch({ ...initialState, updated: true });
  } catch (e) {
    //TODO: check if error message should be e.errorMessage
    dispatch({
      ...initialState,
      error: {
        exist: true,
        message: ServerError(e.code, e.message),
      },
    });
  }
};
