export const GET_SHOPS_REQUEST = 'get_shops_request';
export const GET_SHOPS_SUCCESS = 'get_shops_success';
export const GET_SHOPS_FAIL = 'get_shops_fail';

export const CREATE_SHOP_REQUEST = 'create_shop_request';
export const CREATE_SHOP_SUCCESS = 'create_shop_success';
export const CREATE_SHOP_FAIL = 'create_shop_fail';

export const UPDATE_SHOP_REQUEST = 'update_shop_request';
export const UPDATE_SHOP_SUCCESS = 'update_shop_success';
export const UPDATE_SHOP_FAIL = 'update_shop_fail';

export const GET_SHOP_REQUEST = 'get_shop_request';
export const GET_SHOP_SUCCESS = 'get_shop_success';
export const GET_SHOP_FAIL = 'get_shop_fail';