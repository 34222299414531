import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useUserContext } from '../contexts/UserContext';
import DashboardLayout from '../layouts/Dashboard';

const RequireAuth = (props) => {
  const [userState] = useUserContext();
  const location = useLocation();

  if (userState.user.exist && userState.user.verified) {
    return (
      <DashboardLayout {...props} location={location}>
        {props.children}
      </DashboardLayout>
    );
  }

  return <Navigate to="/login" state={{ from: location }} replace={true} />;
};

export default RequireAuth;
