import axios from 'axios';
import { AppErrorFormat } from '../helpers';

const userEndPoint = process.env.REACT_APP_API_URI + 'business-users';
const userSettingsEndPoint = process.env.REACT_APP_API_URI + 'users/settings';
const userWebTokenEndPoint = process.env.REACT_APP_API_URI + 'users/tokens';

export const UserService = {
  /**
   * Create a user.
   *
   * @param {object} token
   *
   * @returns {object}
   */
  async create(token, subscription) {
    return axios({
      method: 'post',
      url: userEndPoint,
      timeout: process.env.REACT_APP_API_REQUEST_TIMEOUT,
      headers: {
        Authorization: token,
      },
      data: {
        subscription: subscription,
      },
    })
      .then((response) => response)
      .catch((error) =>
        AppErrorFormat(error.response.status, error.response.data)
      );
  },

  /**
   * Get user details.
   *
   * @param {string} token
   *
   * @returns {object}
   */
  get(token) {
    return { token };

    /*
    return axios.get(
      userEndPoint,
      {
        headers: {
          'Authorization': token
        },
        timeout: process.env.REACT_APP_API_REQUEST_TIMEOUT
      }
    )
      .then(response => (response))
      .catch(error => (AppErrorFormat(error.response.status, error.response.data)));
      */
  },

  /**
   * Get user settings.
   *
   * @param {string} token
   *
   * @returns {object}
   */
  getSettings(token) {
    return axios({
      method: 'get',
      url: userSettingsEndPoint,
      timeout: process.env.REACT_APP_API_REQUEST_TIMEOUT,
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response)
      .catch((error) =>
        AppErrorFormat(error.response.status, error.response.data)
      );
  },

  /**
   * Get user settings.
   *
   * @param {string} token
   * @param {object} details
   *
   * @returns {object}
   */
  updateSettings(token, details) {
    return axios({
      method: 'put',
      url: userSettingsEndPoint,
      timeout: process.env.REACT_APP_API_REQUEST_TIMEOUT,
      data: details,
      headers: {
        Authorization: token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) =>
        AppErrorFormat(error.response.status, error.response.data)
      );
  },

  /**
   * Send user browser token.
   *
   * @param {string} token
   * @param {string} webToken
   *
   * @returns {object}
   */
  sendWebToken(token, webToken) {
    return axios({
      method: 'post',
      url: userWebTokenEndPoint,
      timeout: process.env.REACT_APP_API_REQUEST_TIMEOUT,
      data: {
        token: webToken,
      },
      headers: {
        Authorization: token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) =>
        AppErrorFormat(error.response.status, error.response.data)
      );
  },
};
