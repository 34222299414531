export const PASSWORD_SIGN_UP_REQUEST = 'password_sign_up_request';
export const PASSWORD_SIGN_UP_SUCCESS = 'password_sign_up_success';
export const PASSWORD_SIGN_UP_FAIL = 'password_sign_up_fail';

export const PASSWORD_LOGIN_REQUEST = 'password_login_request';
export const PASSWORD_LOGIN_SUCCESS = 'password_login_success';
export const PASSWORD_LOGIN_FAIL = 'password_login_fail';

export const LOGOUT_REQUEST = 'logout_request';
export const LOGOUT_SUCCESS = 'logout_success';
export const LOGOUT_FAIL = 'logout_fail';

export const RESET_PASSWORD_REQUEST = 'reset_password_request';
export const RESET_PASSWORD_SUCCESS = 'reset_password_success';
export const RESET_PASSWORD_FAIL = 'reset_password_fail';

export const CLEAR_ERROR = 'clear_error';
export const CLEAR_MESSAGE = 'clear_message';

export const CLEAR_AUTH_STATE_REQUEST = 'clear_auth_state_request';
