import React from 'react';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import GridItem from '../../components/Grid/Item';
import GridContainer from '../../components/Grid/Container';
import Card from '../../components/Card';
import CardHeader from '../../components/Card/Header';
import CardBody from '../../components/Card/Body';
import CustomButton from '../../components/CustomButton';

import styles from './styles';

const Support = ({ classes }) => {
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
              <h4>Support</h4>
            </CardHeader>
            <CardBody>
              <p style={{ fontSize: 15 }}>
                You can contact us via email on{' '}
                <a
                  href="mailto:contact@stampa.app"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.link}
                >
                  contact@stampa.app
                </a>
              </p>
              <p>Or click the following button to send us an email.</p>
              <p>
                <a
                  href="mailto:contact@stampa.app"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <CustomButton color="success">Send Email</CustomButton>
                </a>
              </p>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default withStyles(styles)(Support);
