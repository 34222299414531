import axios from 'axios';

const notificationsEndPoint = process.env.REACT_APP_API_URI + 'rewards';

export const NotificationService = {
  async getNotifications(token) {
    return axios({
      method: 'get',
      url: notificationsEndPoint,
      timeout: process.env.REACT_APP_API_REQUEST_TIMEOUT,
      headers: {
        Authorization: token,
      },
    });
  },
};
