import React, { useEffect, useState } from 'react';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import { checkSuccessfulSubscription } from '../../actions/subscription/checkSuccessfulSubscription';

// core components
import GridItem from '../../components/Grid/Item';
import GridContainer from '../../components/Grid/Container';
import Card from '../../components/Card';
import CardHeader from '../../components/Card/Header';
import CardBody from '../../components/Card/Body';

const SuccessSubscription = () => {
  const [sessionId, setSessionId] = useState(null);

  useEffect(() => {
    const url = new URL(window.location.href);
    setSessionId(url.searchParams.get('session_id'));
  }, []);

  useEffect(() => {
    if (sessionId !== null && typeof sessionId === 'string') {
      // send request to API to check
      checkSuccessfulSubscription({
        checkout_session_id: sessionId,
      });
    }
  }, [sessionId]);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
              <h4>Success Subscription</h4>
            </CardHeader>
            <CardBody>
              <h3>Subscription was successful!</h3>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default SuccessSubscription;
