import React, { useEffect } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import CustomButton from '../../components/CustomButton';

import stampaLogo from '../../assets/img/logo.png';

import { useUserContext } from '../../contexts/UserContext';

import { CLEAR_AUTH_STATE_REQUEST } from '../../actions/types/user';

import styles from './styles';

const ResetPasswordPage = ({ classes }) => {
  const [, userDispatch] = useUserContext();
  useEffect(() => {
    userDispatch({
      type: CLEAR_AUTH_STATE_REQUEST,
      payload: null,
    });
  }, []);

  return (
    <main className={classes.main}>
      <CssBaseline />
      <Paper className={classes.paper}>
        <Avatar alt="Stampa" src={stampaLogo} className={classes.avatar} />

        <Typography component="h1" variant="h5" className={classes.h1}>
          Reset My Password
        </Typography>

        <MailOutlineIcon color="action" style={{ fontSize: 150 }} />

        <Typography component="h2" variant="h6" className={classes.h2}>
          Almost there...
        </Typography>

        <div className={classes.paragraph}>
          Please check your e-mail and follow the instructions to reset your
          password
        </div>

        <CustomButton block color="success" href="/login">
          Login
        </CustomButton>
      </Paper>
    </main>
  );
};

export default withStyles(styles)(ResetPasswordPage);

/*
class ResetPasswordPage extends Component {
  componentDidMount() {
    this.props.clearAuthState();
  }

  render() {
    const { classes } = this.props;

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Avatar alt="Stampa" src={stampaLogo} className={classes.avatar} />

          <Typography component="h1" variant="h5" className={classes.h1}>
            Reset My Password
          </Typography>

          <MailOutlineIcon color="action" style={{ fontSize: 150 }} />

          <Typography component="h2" variant="h6" className={classes.h2}>
            Almost there...
          </Typography>

          <div className={classes.paragraph}>
            Please check your e-mail and follow the instructions to reset your
            password
          </div>

          <CustomButton block color="success" href="/login">
            Login
          </CustomButton>
        </Paper>
      </main>
    );
  }
}

function mapStateToProps({ user }) {
  return { user };
}

function mapDispatchToProps(dispatch) {
  return {
    clearAuthState: () => dispatch(clearAuthStateAction()),
  };
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage)
);
*/
