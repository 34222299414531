import React from 'react';
import { Navigate, useParams } from 'react-router-dom';

// core components
import GridItem from '../../../components/Grid/Item';
import GridContainer from '../../../components/Grid/Container';
import Card from '../../../components/Card';
import CardHeader from '../../../components/Card/Header';

import Shop from './Shop';
import StampOffer from './StampOffer';

const ShopDetails = () => {
  const params = useParams();
  const shopId = params.id;

  if (!shopId && typeof shopId !== 'number') {
    return <Navigate to="/shops" replace={true} />;
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="success">
              <h4>Business Profile</h4>
            </CardHeader>
            <Shop shopId={shopId} />
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="success">
              <h4>Stamps</h4>
            </CardHeader>
            <StampOffer shopId={shopId} />
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default ShopDetails;
