import { FirebaseService } from '../../services/Firebase';
import { LocalStorageService } from '../../services/LocalStorage';
import { AppErrors, throwExceptionIfErrorExists } from '../../helpers';

import {
  PASSWORD_LOGIN_REQUEST,
  PASSWORD_LOGIN_SUCCESS,
  PASSWORD_LOGIN_FAIL,
} from '../types/user';

export const loginUserAction = async (payload, dispatch) => {
  dispatch({
    type: PASSWORD_LOGIN_REQUEST,
    payload: null,
  });

  try {
    // login user in firebase
    const user = await FirebaseService.login(payload);
    throwExceptionIfErrorExists(user);

    const userPayload = {
      name: user.name,
      email: user.email,
      verified: user.verified,
      exist: true,
    };

    // insert user into memory if user is verified
    if (user.verified) {
      LocalStorageService.insertItem('user', JSON.stringify(userPayload));
    }

    dispatch({
      type: PASSWORD_LOGIN_SUCCESS,
      payload: {
        user: userPayload,
      },
    });
  } catch (e) {
    dispatch({
      type: PASSWORD_LOGIN_FAIL,
      payload: {
        errorMessage: AppErrors(e.errorCode, e.errorMessage),
      },
    });
  }
};
