import axios from 'axios';
import { AppErrorFormat } from '../helpers';

const shopsEndPoint = process.env.REACT_APP_API_URI + 'shops';

export const ShopService = {
  /**
   * Get user details.
   *
   * @param {string} token
   *
   * @returns {Array}
   */
  async getShops(token) {
    return axios({
      method: 'get',
      url: shopsEndPoint + '?mode=business',
      timeout: process.env.REACT_APP_API_REQUEST_TIMEOUT,
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response)
      .catch((error) => {
        if (error.response === undefined) {
          return AppErrorFormat(500, '');
        } else {
          return AppErrorFormat(error.response.status, error.response.data);
        }
      });
  },

  async createShop(token, shopDetails) {
    return axios({
      method: 'post',
      url: shopsEndPoint,
      timeout: process.env.REACT_APP_API_REQUEST_TIMEOUT,
      headers: {
        Authorization: token,
      },
      data: {
        name: shopDetails.business_name,
        description: shopDetails.business_description,
        logo: shopDetails.business_logo,
        address: shopDetails.business_address,
        postcode: shopDetails.business_postcode,
        offer_description: shopDetails.reward_description,
        offer_num_stamps: shopDetails.stamps_number,
        offer_policy: shopDetails.reward_policy
          ? shopDetails.reward_policy
          : '',
      },
    })
      .then((response) => response)
      .catch((error) =>
        AppErrorFormat(error.response.status, error.response.data)
      );
  },

  async updateShop(token, shopId, shopDetails) {
    return axios({
      method: 'put',
      url: `${shopsEndPoint}/${shopId}`,
      timeout: process.env.REACT_APP_API_REQUEST_TIMEOUT,
      headers: {
        Authorization: token,
      },
      data: {
        name: shopDetails.name,
        description: shopDetails.description,
        logo: shopDetails.logo,
        address: shopDetails.address,
        postcode: shopDetails.postcode,
      },
    })
      .then((response) => response)
      .catch((error) =>
        AppErrorFormat(error.response.status, error.response.data)
      );
  },

  /**
   * Get user details.
   *
   * @param {string} token
   * @param {string} shopId
   *
   * @returns {object}
   */
  async getShop(token, shopId) {
    return axios({
      method: 'get',
      url: shopsEndPoint + '/' + shopId,
      timeout: process.env.REACT_APP_API_REQUEST_TIMEOUT,
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response)
      .catch((error) => {
        if (error.response === undefined) {
          return AppErrorFormat(500, '');
        } else {
          return AppErrorFormat(error.response.status, error.response.data);
        }
      });
  },
};
