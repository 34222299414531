const styles = {
  cardBodyText: {
    paddingTop: 10,
    fontSize: 16,
  },
  h3ShopName: {
    color: '#777 !important',
    paddingTop: 10,
  },
};

export default styles;
