import React, { Component } from 'react';
import classNames from 'classnames';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Snack from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
// @material-ui/icons
import Close from '@material-ui/icons/Close';

import styles from './styles';

class CustomSnackbarContent extends Component {
  state = {
    open: this.props.open,
  };

  render() {
    const { classes, message, color, close, icon } = this.props;
    let action = [];

    const messageClasses = classNames({
      [classes.iconMessage]: icon !== undefined,
    });

    if (!this.state.open) {
      return null;
    }

    if (close !== undefined && close === true) {
      action = [
        <IconButton
          className={classes.iconButton}
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => this.setState({ open: false })}
        >
          <Close className={classes.close} />
        </IconButton>,
      ];
    }

    return (
      <Snack
        message={
          <div>
            {icon !== undefined ? (
              <this.props.icon className={classes.icon} />
            ) : null}
            <span className={messageClasses}>{message}</span>
          </div>
        }
        classes={{
          root: classes.root + ' ' + classes[color],
          message: classes.message,
        }}
        action={action}
      />
    );
  }
}

export default withStyles(styles)(CustomSnackbarContent);
