/**
 * Gets the error code and returns the error message.
 *
 * @param {string} code
 * @param {string} message
 *
 * @returns {string}
 */
export const AppErrors = (
  code,
  message = 'There was an error with the last action. Please try again.'
) => {
  switch (code) {
    case 'auth/invalid-email':
      return 'The email address is badly formatted.';
    case 'auth/wrong-password':
      return 'The username or password is wrong.';
    case 'auth/user-not-found':
      return 'There is no an account for this email.';
    case 'auth/weak-password':
      return 'The password must be 6 characters long or more.';
    case 'auth/email-already-in-use':
      return 'The email address is already in use.';
    case 'auth/auth-failed':
      return 'Authentication failed.';
    case 'auth/google-failed':
      return 'Authentication with Google failed.';
    case 'auth/facebook-failed':
      return 'Authentication with Facebook failed.';
    case 'net/no-internet-connection':
      return 'There is no internet connection.';
    default:
      return message;
  }
};

export const ServerError = (code, message) => {
  switch (parseInt(code)) {
    case 500:
      return 'There is a problem with the server. Please try again later.';
    case 401:
      return 'It seems that you are not properly authorized. Please try again later. If the issue persist, please contact support.';
    default:
      return message;
  }
};

export const getServerError = (code, message) => {
  const result = {
    hideContent: false,
    errorMessage: message,
    closeMessage: true,
  };

  switch (parseInt(code)) {
    case 500:
      result.errorMessage =
        'There is a problem with the server. Please try again later.';
      result.hideContent = true;
      result.closeMessage = false;
      break;
    case 401:
      result.errorMessage =
        'It seems that you are not properly authorized. Please try again later. If the issue persist, please contact support.';
      result.hideContent = true;
      result.closeMessage = false;
      break;
  }

  return result;
};

/**
 * Return the format of the error response.
 *
 * @param {string} code
 * @param {string} message
 *
 * @returns {object}
 */
export const AppErrorFormat = (code = '', message = '') => {
  return {
    error: {
      code,
      message,
    },
  };
};
