/* eslint-disable no-undef */
export const LocalStorageService = {
  async selectItem(key) {
    return Promise.resolve().then(() => {
      const data = localStorage.getItem(key);

      return this.isJson(data) ? JSON.parse(data) : data;
    }, this);
  },

  insertItem(key, value) {
    localStorage.setItem(key, value);

    return true;
  },

  deleteItem(key) {
    localStorage.removeItem(key);
  },

  isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  },
};
