import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomButton from '../CustomButton';

const CustomDialog = ({ title, text, open, onClose, buttons }) => {
  const renderButtons = () => {
    return buttons.map((button, key) => {
      return (
        <CustomButton onClick={button.onClick} color={button.color} key={key}>
          {button.text}
        </CustomButton>
      );
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>{renderButtons()}</DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
