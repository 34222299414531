import React from 'react';
import { Controller } from 'react-hook-form';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const CustomField = ({
  type,
  name,
  label,
  control,
  id = '',
  rules = {},
  errors = {},
  showPassword = false,
  handleClickShowPassword = null,
  defaultValue = '',
  autoComplete = 'on',
  inputProps = {},
}) => {
  const renderShowPasswordIcon = () => {
    return (
      <InputAdornment position="end">
        <IconButton
          aria-label="Toggle password visibility"
          onClick={handleClickShowPassword}
        >
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    );
  };

  const renderError = (name) => {
    if (errors[name]) {
      return (
        <FormHelperText style={{ color: 'red', fontSize: '14px' }}>
          {errors[name].message}
        </FormHelperText>
      );
    }

    return null;
  };

  const renderInput = (
    field,
    id,
    type,
    name,
    autoComplete,
    showPassword,
    inputProps
  ) => {
    if (type === 'password') {
      return (
        <Input
          {...field}
          id={id}
          type={showPassword ? 'text' : 'password'}
          endAdornment={renderShowPasswordIcon()}
          name={name}
          autoComplete={autoComplete}
          inputProps={inputProps}
        />
      );
    }

    if (type === 'textarea') {
      return <Input {...field} id={id} type="text" name={name} multiline />;
    }

    return (
      <Input
        {...field}
        id={id}
        type={type}
        name={name}
        autoComplete={autoComplete}
        inputProps={inputProps}
      />
    );
  };

  const renderInputComponent = ({
    field,
    id,
    type,
    label,
    name,
    autoComplete,
    showPassword,
    inputProps,
  }) => {
    return (
      <FormControl margin="dense" fullWidth>
        <InputLabel htmlFor={id} style={{ color: '#008000' }}>
          {label}
        </InputLabel>
        {renderInput(
          field,
          id,
          type,
          name,
          autoComplete,
          showPassword,
          inputProps
        )}
        {renderError(name)}
      </FormControl>
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field }) => {
        return renderInputComponent({
          field,
          id,
          type,
          label,
          name,
          autoComplete,
          showPassword,
          inputProps,
        });
      }}
    />
  );
};

export default CustomField;
