const styles = {
  cardBody: {
    paddingTop: 40,
    paddingBottom: 40,
  },
  box: {
    paddingTop: 1,
    paddingLeft: 10,
    paddingRight: 10,
    fontSize: 16,
    marginBottom: 40,
    paddingBottom: 10,
  },
  boxHeader: {
    color: '#008800',
  },
};

export default styles;
