import { FirebaseService } from '../../services/Firebase';
import { RewardService } from '../../services/Reward';

import { HTTP_NO_CONTENT } from '../../config';

export const initialState = {
  updated: false,
  error: {
    exist: false,
    message: '',
  },
  processing: false,
};

export const updateRewardStatusAction = async (dispatch, id, status) => {
  dispatch({ ...initialState, processing: true });
  const genericMessage =
    'There was an error. Please try again later. If the error still exists, please contact support';

  try {
    const token = await FirebaseService.getUserToken();

    const response = await RewardService.updateRewardStatus(token, id, status);

    if (response.status === undefined) {
      dispatch({
        ...initialState,
        error: {
          exist: true,
          message: genericMessage,
        },
      });
    } else if (response.status !== HTTP_NO_CONTENT) {
      dispatch({
        ...initialState,
        error: {
          exist: true,
          message: response.data.message,
        },
      });
    } else {
      dispatch({ ...initialState, updated: true });
    }
  } catch (e) {
    dispatch({
      ...initialState,
      error: {
        exist: true,
        message: genericMessage,
      },
    });
  }
};
