const styles = {
  link: {
    color: '#008800',
    fontWeight: 'bold',
    fontSize: 18,
    '&:hover': {
      color: '#008000',
      textDecorationLine: 'underline',
    },
    '&:visited': {
      color: '#008000',
    },
  },
  text: {
    color: '#008800',
    fontWeight: 'bold',
  },
};

export default styles;
