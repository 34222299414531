import React, { useEffect, useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import LoadingOverlay from 'react-loading-overlay-ts';
import HashLoader from 'react-spinners/HashLoader';
// core components
import GridItem from '../../components/Grid/Item';
import GridContainer from '../../components/Grid/Container';
import Card from '../../components/Card';
import CardHeader from '../../components/Card/Header';
import CardBody from '../../components/Card/Body';
import CustomSnackbarContent from '../../components/CustomSnackbarContent';
import CustomButton from '../../components/CustomButton';

import {
  getBillingUrl,
  billingLinkInitialState,
} from '../../actions/subscription/getBillingUrl';

import styles from './styles';

const Billing = ({ classes }) => {
  const [billingState, setBillingState] = useState(billingLinkInitialState);

  useEffect(() => {
    getBillingUrl(setBillingState, {
      return_url: `${window.location.origin}/billing`,
    });
  }, []);

  const renderErrorMessage = () => {
    if (billingState.errorMessage) {
      return (
        <div style={{ marginBottom: 10 }}>
          <CustomSnackbarContent
            message={billingState.errorMessage}
            close
            open
            color="danger"
          />
        </div>
      );
    }
  };

  const renderTextContent = () => {
    if (billingState.url) {
      return 'Click the following button to visit your billing page on Stripe.';
    }

    return billingState.message;
  };

  const billingButton = () => {
    if (!billingState.url) {
      return null;
    }

    return (
      <CustomButton
        size="sm"
        color="success"
        onClick={() => (window.location.href = billingState.url)}
      >
        Stripe Billing Portal
      </CustomButton>
    );
  };

  return (
    <div>
      {renderErrorMessage()}
      <LoadingOverlay
        active={billingState.processing}
        spinner={<HashLoader color="#4caf50" />}
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4>Billing</h4>
              </CardHeader>
              <CardBody>
                <p style={{ fontSize: 15 }}>{renderTextContent()}</p>
                <div style={{ textAlign: 'center' }}>{billingButton()}</div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </LoadingOverlay>
    </div>
  );
};

export default withStyles(styles)(Billing);
