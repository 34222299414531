import { FirebaseService } from '../../services/Firebase';
import { UserService } from '../../services/User';

export const sendWebTokenAction = async (webToken) => {
  try {
    const token = await FirebaseService.getUserToken();

    await UserService.sendWebToken(token, webToken);

    // TODO: log the error if differ from 201 or (already exists) status
    // const response = await UserService.sendWebToken(token, webToken);

    // console.log("response", response);

    // if (response.status !== 201 && response.error.code === 422) {
    //   // This token already exists in the database
    //   console.log("in", response.error.message.token[0]);
    // } else {
    //   console.log("out", response.error.message.token[0]);
    // }
  } catch (e) {
    // TODO: log the error
    // console.log('error', e);
  }
};
