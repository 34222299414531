import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import withStyles from '@material-ui/core/styles/withStyles';

import GridItem from '../../../components/Grid/Item';
import GridContainer from '../../../components/Grid/Container';
import Card from '../../../components/Card';
import CardHeader from '../../../components/Card/Header';
import CardBody from '../../../components/Card/Body';
import CardFooter from '../../../components/Card/Footer';
import CustomField from '../../../components/CustomField';
import CustomButton from '../../../components/CustomButton';
import FileUploader from '../../../components/FileUploader';

import styles from './styles';

const AddShopForm = ({ onSubmit, classes }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [fileExist, setFileExist] = useState(false);

  useEffect(() => {
    if (fileExist === false) {
      setValue('file_upload', '');
    }
  }, [fileExist]);

  const onFileChange = (e, field) => {
    const file = e.target.files[0];
    if (Math.round(file.size / 1024) > 1000) {
      field.onChange('');
      alert(
        'The file size is more than 1MB. Please try to upload another one.'
      );
      setFileExist(false);
    } else {
      field.onChange(e.target.files[0]);
      setFileExist(true);
    }
  };

  const onRemoveFile = () => {
    setFileExist(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
      <Card>
        <CardHeader color="success">
          <h4 style={{ margin: 0 }}>Add your business</h4>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <CustomField
                name="business_name"
                control={control}
                errors={errors}
                rules={{
                  required: 'Business Name is required',
                }}
                id="business-name"
                label="Business Name"
                type="text"
              />
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <CustomField
                name="business_description"
                control={control}
                errors={errors}
                rules={{
                  required: 'Business Description is required',
                }}
                id="business-description"
                label="Business Description"
                type="text"
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <CustomField
                name="business_address"
                control={control}
                errors={errors}
                rules={{
                  required: 'Business Address is required',
                }}
                id="business-address"
                label="Business Address"
                type="text"
              />
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <CustomField
                name="business_postcode"
                control={control}
                errors={errors}
                rules={{
                  required: 'Business Postcode is required',
                }}
                id="business-postcode"
                label="Business Postcode"
                type="text"
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div style={{ paddingTop: 20, fontSize: 17 }}>
                <div
                  style={{
                    float: 'left',
                    marginRight: 10,
                    color: '#008000',
                    fontWeight: 400,
                  }}
                >
                  Business Logo:
                </div>
                <div style={{ float: 'left' }}>
                  <FileUploader
                    onFileChange={onFileChange}
                    accept="image/*"
                    name="file_upload"
                    fileExist={fileExist}
                    onRemoveFile={onRemoveFile}
                    control={control}
                  />
                </div>
                <div style={{ clear: 'both' }}></div>
              </div>
              <div
                style={{
                  marginTop: 5,
                  fontSize: 12,
                  color: 'orange',
                  fontWeight: 'bold',
                }}
              >
                *Maximum file size could be up to 1MB. We recommend logos with
                dimensions between 512px to 1024px.
              </div>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <CardHeader
              color="success"
              style={{ marginTop: 20, width: '100%' }}
            >
              <h4 style={{ margin: 0 }}>
                Add the stamps details for your business
              </h4>
            </CardHeader>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={8} md={8}>
              <CustomField
                name="reward_description"
                control={control}
                errors={errors}
                rules={{
                  required: 'Description is required',
                }}
                id="reward-description"
                label="Description about the reward"
                type="text"
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <CustomField
                name="stamps_number"
                control={control}
                errors={errors}
                rules={{
                  required: 'Number of stamps is required',
                  pattern: {
                    value: /^[0-9]+$/,
                    message: 'Number of stamps should be a number',
                  },
                }}
                id="stamps-number"
                label="Number of stamps"
                type="number"
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <CustomField
                name="reward_policy"
                control={control}
                id="reward-policy"
                label="Privacy policy for the reward (optional)"
                type="textarea"
              />
            </GridItem>
          </GridContainer>
        </CardBody>
        <CardFooter>
          <CustomButton color="success" type="submit">
            Add Business
          </CustomButton>
        </CardFooter>
      </Card>
    </form>
  );
};

export default withStyles(styles)(AddShopForm);
