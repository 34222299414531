import React, { createContext, useContext, useReducer } from 'react';
import userReducer, { initialState } from '../reducers/user';

const UserContext = createContext([initialState, () => ({})]);

export const UserProvider = ({ children, user }) => {
  const newState = user === null ? initialState : { ...initialState, user };

  const value = useReducer(userReducer, newState);
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserContext = () => useContext(UserContext);
