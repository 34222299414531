import { FirebaseService } from '../../services/Firebase';
import { AppErrors, throwExceptionIfErrorExists } from '../../helpers';

import {
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
} from '../types/user';

export const resetPasswordAction = async (email, dispatch) => {
  dispatch({
    type: RESET_PASSWORD_REQUEST,
    payload: null,
  });

  try {
    // reset password for user in firebase
    const response = await FirebaseService.resetPassword(email);
    throwExceptionIfErrorExists(response);

    dispatch({
      type: RESET_PASSWORD_SUCCESS,
      payload: null,
    });
  } catch (e) {
    dispatch({
      type: RESET_PASSWORD_FAIL,
      payload: {
        errorMessage: AppErrors(e.errorCode, e.errorMessage),
      },
    });
  }
};
