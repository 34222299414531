/**
 * Build error exception.
 *
 * @param {string} code
 * @param {string} message
 *
 * @returns {Error}
 */
export const AppException = (code, message) => {
  const error = new Error(message);

  error.code = code;
  error.message = message;
  error.errorCode = code;
  error.errorMessage = message;

  return error;
};

/**
 * Build error exception.
 *
 * @param {string} code
 * @param {string} message
 *
 * @returns {Error}
 */
export const ThrowException = (code, message) => {
  const error = new Error(message);

  error.code = code;
  error.message = message;

  return error;
};

/**
 * Throw the error as exception from the error response if exists.
 *
 * @param {string} errorResponse
 * @param {string} customCode
 * @param {string} customMessage
 *
 * @throws {Error}
 */
export const throwExceptionIfErrorExists = (
  errorResponse,
  customCode = '',
  customMessage = ''
) => {
  if (
    errorResponse !== undefined &&
    Object.prototype.hasOwnProperty.call(errorResponse, 'error')
  ) {
    if (customCode !== '' && customMessage !== '') {
      throw AppException(customCode, customMessage);
    }

    throw AppException(errorResponse.error.code, errorResponse.error.message);
  }
};

/**
 * Throw the error if there is no internet connection.
 *
 * @param {boolean} isConnected
 *
 * @throws {Error}
 */
export const throwExceptionForNoInternetConnection = (isConnected) => {
  if (isConnected === false) {
    throw AppException(
      'net/no-internet-connection',
      'Internet connection is needed.'
    );
  }
};

/**
 * Throw the error if authentication failed with socials.
 *
 * @param {string} type
 * @param {string} social
 *
 * @throws {Error}
 */
export const throwExceptionForFailedSocialAuth = (type, social) => {
  if (type !== 'success') {
    throw AppException(
      `auth/${social}-failed`,
      `Authentication with ${social} failed.`
    );
  }
};

/**
 * Throw the error if user is missing from the API.
 *
 * @param {object} errorResponse
 *
 * @throws {Error}
 */
export const throwExceptionForMissingUser = (errorResponse) => {
  throwExceptionIfErrorExists(
    errorResponse,
    'firebase/no-user-deleted',
    'There was a problem. Please contact App administrator.'
  );

  throw AppException(
    'api/no-user-creation',
    'There was a problem. Please try sign up/login again.'
  );
};
