import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import Menu from '@material-ui/icons/Menu';
import _ from 'lodash';

import { useUserContext } from '../../contexts/UserContext';

import styles from './style';

const Header = ({ ...props }) => {
  const [userState] = useUserContext();

  // const getBrand = () => {
  //   let name;

  //   props.routes.map((prop, key) => {
  //     if (prop.path === props.location.pathname) {
  //       name = prop.navbarName;
  //     }
  //     return null;
  //   });

  //   return name;
  // };

  const getSalute = () => {
    if (_.isEmpty(userState.user.name)) {
      return null;
    }
    return `Hi ${userState.user.name.split(' ')[0]},`;
  };

  const { classes } = props;

  return (
    <AppBar className={classes.appBar}>
      <Toolbar className={classes.container}>
        <div className={classes.flex}>{getSalute()}</div>
        <Hidden mdUp implementation="css">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(styles)(Header);
