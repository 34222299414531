import React, { useEffect, useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import LoadingOverlay from 'react-loading-overlay-ts';
import HashLoader from 'react-spinners/HashLoader';

import GridContainer from '../../../../components/Grid/Container';
//import CustomInput from "components/CustomInput";
import CustomButton from '../../../../components/CustomButton';
//import CardAvatar from "components/Card/Avatar";
import CardBody from '../../../../components/Card/Body';
import CardFooter from '../../../../components/Card/Footer';
import FirebaseLogo from '../../../../components/FirebaseLogo';
import CustomSnackbarContent from '../../../../components/CustomSnackbarContent';

import EditShopForm from '../../../../forms/Shop/Edit';

import avatar from '../../../../assets/img/shop-logo.png';

import {
  getShopAction,
  initialState as shopInitialState,
} from '../../../../actions/shop/getShop';

import {
  updateShopAction,
  initialState as updateShopInitialState,
} from '../../../../actions/shop/updateShop';

const Shop = ({ shopId }) => {
  const [editMode, setEditMode] = useState(false);
  const [shop, setShop] = useState(shopInitialState);
  const [updateShopStatus, setUpdateShopStatus] = useState(
    updateShopInitialState
  );

  useEffect(() => {
    getShopAction(shopId, setShop);
  }, []);

  useEffect(() => {
    if (updateShopStatus.updated) {
      setEditMode(false);
      getShopAction(shopId, setShop);
    }
  }, [updateShopStatus]);

  const onUpdateShopRequest = (formValues) => {
    // formValues.file =
    //   Object.prototype.hasOwnProperty.call(formValues, 'file_upload') &&
    //   formValues.file_upload.length > 0
    //     ? formValues.file_upload[0]
    //     : null;

    formValues.file =
      formValues.file_upload !== '' ? formValues.file_upload : null;
    updateShopAction(shopId, formValues, setUpdateShopStatus);
  };

  const onCancelEditShop = () => {
    setEditMode(false);
  };

  const renderShopForm = () => {
    return (
      <LoadingOverlay
        active={updateShopStatus.processing}
        spinner={<HashLoader color="#4caf50" />}
      >
        <EditShopForm
          onSubmit={onUpdateShopRequest}
          onCancel={onCancelEditShop}
          initialValues={shop.data}
          logo={renderLogo()}
        />
      </LoadingOverlay>
    );
  };

  const showErrorMessages = (errorObj) => {
    if (errorObj.error.exist && errorObj.error.message !== undefined) {
      const errorMessages = errorObj.error.message;

      if (typeof errorMessages === 'string') {
        return (
          <div style={{ marginTop: 10 }}>
            <CustomSnackbarContent
              message={errorMessages}
              close
              open
              color="danger"
            />
          </div>
        );
      } else {
        for (var k in errorMessages) {
          return errorMessages[k].map((message, key) => {
            return (
              <div style={{ marginTop: 10 }} key={key}>
                <CustomSnackbarContent
                  message={message}
                  close
                  open
                  color="danger"
                />
              </div>
            );
          });
        }
      }
    }

    return null;
  };

  const renderLogo = () => {
    return (
      <FirebaseLogo
        defaultLogoUrl={avatar}
        logoUrl={shop.data.logo}
        style={{ width: 80, height: 80 }}
      />
    );
  };

  const renderShopView = () => {
    if (shop.processing) {
      return (
        <LoadingOverlay active={true} spinner={<HashLoader color="#4caf50" />}>
          <div style={{ width: '100%', height: 150 }}>&nbsp;</div>
        </LoadingOverlay>
      );
    }

    if (Object.keys(shop.data).length === 0) {
      return null;
    }

    if (shop.error.exist) {
      return <CardBody>{showErrorMessages(shop)}</CardBody>;
    }

    return (
      <React.Fragment>
        <CardBody>
          <GridContainer>
            <List dense={false}>
              <ListItem>
                <ListItemAvatar style={{ marginRight: 20 }}>
                  {renderLogo()}
                </ListItemAvatar>
                <ListItemText
                  primaryTypographyProps={{
                    style: { fontSize: 24 },
                  }}
                  primary={shop.data.name}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Description:"
                  secondary={shop.data.description}
                />
              </ListItem>
              <ListItem>
                <ListItemText primary="Address:" />
                <ListItemText primary={shop.data.address} />
              </ListItem>
              <ListItem>
                <ListItemText primary="Postcode:" />
                <ListItemText primary={shop.data.postcode} />
              </ListItem>
            </List>
          </GridContainer>
        </CardBody>
        <CardFooter>
          <CustomButton color="success" onClick={() => setEditMode(true)}>
            Edit Profile
          </CustomButton>
        </CardFooter>
      </React.Fragment>
    );
  };

  if (editMode) {
    return renderShopForm();
  }

  return renderShopView();
};

export default Shop;
/*
class Shop extends Component {
  state = {
    editMode: false,
    file: null,
    removeLogo: false,
  };

  componentDidMount() {
    if (this.props.shopId) {
      this.props.getShop(this.props.shopId);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.shop.shop) !==
      JSON.stringify(this.props.shop.shop)
    ) {
      this.setState({ editMode: false, file: null, removeLogo: false });
    }
  }

  onUpdateShopRequest = (formValues) => {
    formValues.file = this.state.file;
    formValues.remove_logo = this.state.removeLogo;

    this.props.updateShop(this.props.shopId, formValues);
  };

  onCancelEditShop = () => {
    this.setState({ editMode: false, file: null, removeLogo: false });
  };

  onFileChange = (e) => {
    const file = e.target.files[0];

    this.setState({ file });
  };

  onRemoveLogo = (status) => {
    this.setState({ removeLogo: status });
  };

  renderLogo = () => {
    return (
      <FirebaseLogo
        defaultLogoUrl={avatar}
        logoUrl={this.props.shop.shop.logo}
      />
    );
  };

  showErrorMessages = () => {
    if (
      this.props.shop.error.exist &&
      this.props.shop.error.message !== undefined
    ) {
      const errorMessages = this.props.shop.error.message;

      for (var k in errorMessages) {
        return errorMessages[k].map((message, key) => {
          return (
            <div style={{ marginTop: 10 }} key={key}>
              <SnackbarContent message={message} close open color="danger" />
            </div>
          );
        });
      }
    }

    return null;
  };

  renderShopView = () => {
    const { shop, processing } = this.props.shop;

    if (processing) {
      return null;
    }

    return (
      <React.Fragment>
        {this.showErrorMessages()}
        <CardBody>
          <GridContainer>
            <List dense={false}>
              <ListItem>
                <ListItemAvatar>{this.renderLogo()}</ListItemAvatar>
                <ListItemText primary={shop.name} />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Description:"
                  secondary={shop.description}
                />
              </ListItem>
              <ListItem>
                <ListItemText primary="Address:" />
                <ListItemText primary={shop.address} />
              </ListItem>
              <ListItem>
                <ListItemText primary="Postcode:" />
                <ListItemText primary={shop.postcode} />
              </ListItem>
            </List>
          </GridContainer>
        </CardBody>
        <CardFooter>
          <Button
            color="success"
            onClick={() => this.setState({ editMode: true })}
          >
            Edit Profile
          </Button>
        </CardFooter>
      </React.Fragment>
    );
  };

  renderShopForm = () => {
    return (
      <LoadingOverlay
        active={this.props.shop.processing}
        spinner={<HashLoader color="#4caf50" />}
      >
        {this.showErrorMessages()}
        <EditShopForm
          onSubmit={this.onUpdateShopRequest}
          onCancel={this.onCancelEditShop}
          onRemoveLogo={this.onRemoveLogo}
          removeLogo={this.state.removeLogo}
          initialValues={this.props.shop.shop}
          logo={this.renderLogo()}
          fileUploader={
            <FileUploader onFileChange={this.onFileChange} accept="image/*" />
          }
        />
      </LoadingOverlay>
    );
  };

  render() {
    if (this.state.editMode) {
      return this.renderShopForm();
    }

    return this.renderShopView();
  }
}

function mapStateToProps({ shop }) {
  return { shop };
}

function mapDispatchToProps(dispatch) {
  return {
    getShop: (shopId) => dispatch(getShopAction(shopId)),
    updateShop: (shopId, shopDetails) =>
      dispatch(updateShopAction(shopId, shopDetails)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Shop);
*/
