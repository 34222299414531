import { FirebaseService } from '../../services/Firebase';
import { SubscriptionService } from '../../services/Subscription';

import { HTTP_OK, HTTP_NOT_FOUND } from '../../config';

export const billingLinkInitialState = {
  processing: false,
  url: null,
  errorMessage: null,
  message: null,
};

export const getBillingUrl = async (dispatch, payload) => {
  dispatch({
    ...billingLinkInitialState,
    processing: true,
  });

  try {
    // get the token from firebase
    const token = await FirebaseService.getUserToken();

    // get subscription from API
    const apiResponse = await SubscriptionService.getBillingUrl(token, payload);

    if (apiResponse === undefined) {
      dispatch({
        ...billingLinkInitialState,
        errorMessage: 'There is an error. Please try again later.',
      });
    } else if (apiResponse.status === HTTP_OK) {
      dispatch({
        ...billingLinkInitialState,
        url: apiResponse.data.data.billing_url,
      });
    } else if (apiResponse.status === HTTP_NOT_FOUND) {
      dispatch({
        ...billingLinkInitialState,
        message: apiResponse.data.message,
      });
    } else {
      dispatch({
        ...billingLinkInitialState,
        errorMessage: apiResponse.data.message,
      });
    }
  } catch (error) {
    dispatch({
      ...billingLinkInitialState,
      errorMessage: error.message,
    });
  }
};
