import {
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAIL,
} from '../actions/types/notification';

export const initialState = {
  notifications: [],
  error: {
    exist: false,
    hideContent: false,
    closeMessage: true,
    message: '',
  },
  processing: false,
};

export default function (state, action) {
  switch (action.type) {
    case GET_NOTIFICATIONS_REQUEST:
      return {
        ...initialState,
        processing: true,
      };
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...initialState,
        notifications: action.payload.notifications,
      };
    case GET_NOTIFICATIONS_FAIL:
      return {
        ...initialState,
        error: {
          closeMessage: action.payload.closeMessage,
          hideContent: action.payload.hideContent,
          message: action.payload.errorMessage,
        },
      };
    default:
      return state;
  }
}
