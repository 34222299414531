import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay-ts';
import HashLoader from 'react-spinners/HashLoader';

import { useUserContext } from '../../contexts/UserContext';
import { logoutUserAction } from '../../actions/user/logoutUser';

const LogoutPage = () => {
  const [userState, userDispatch] = useUserContext();

  useEffect(() => {
    logoutUserAction(userDispatch);
  }, []);

  if (!userState.user.exist) {
    return <Navigate to="/login" replace={true} />;
  }

  return (
    <LoadingOverlay
      active={userState.processing}
      spinner={<HashLoader color="#4caf50" />}
    />
  );
};

export default LogoutPage;
