export const getLocaleDate = (date) => {
  const localeDate = new Date(getDateWithTimezone(date));
  const month = localeDate.toLocaleString('default', { month: 'short' });

  const hour =
    localeDate.getHours() > 9
      ? localeDate.getHours()
      : `0${localeDate.getHours()}`;
  const minute =
    localeDate.getMinutes() > 9
      ? localeDate.getMinutes()
      : `0${localeDate.getMinutes()}`;

  return `${localeDate.getDate()} ${month} ${localeDate.getFullYear()}, ${hour}:${minute}`;
};

export const getNotificationPermission = () => {
  if (!('Notification' in window)) {
    // This browser does not support desktop notification
    return null;
  }

  return Notification.permission;
};

export const isNotificationSupported = () => {
  return 'Notification' in window;
};

const getDateWithTimezone = (date) => {
  const d = new Date(
    `${date.substring(0, 4)}/${date.substring(5, 7)}/${date.substring(
      8,
      10
    )} ${date.substring(11, 19)}`
  );
  const day = d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`;
  const month = d.getMonth() > 9 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`;
  const hour = d.getHours() > 9 ? d.getHours() : `0${d.getHours()}`;
  const minute = d.getMinutes() > 9 ? d.getMinutes() : `0${d.getMinutes()}`;
  const second = d.getSeconds() > 9 ? d.getSeconds() : `0${d.getSeconds()}`;

  return `${d.getFullYear()}-${month}-${day}T${hour}:${minute}:${second}Z`;
};
