import React, { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import { getToken } from 'firebase/messaging';

import { FirebaseService } from '../../services/Firebase';
import { sendWebTokenAction } from '../../actions/webToken/sendWebToken';
import CustomButton from '../CustomButton';
import {
  isNotificationSupported,
  getNotificationPermission,
} from '../../helpers';

export const NotificationPermissionMessage = ({
  canShowMessage = false,
  onSuccess = null,
}) => {
  const [shouldShowMessage, setShouldShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (canShowMessage === true) {
      if (
        isNotificationSupported() &&
        getNotificationPermission() !== 'granted'
      ) {
        setShouldShowMessage(true);
      }
    }
  }, []);

  const getWebToken = () => {
    if (FirebaseService.isMessagingSupported()) {
      const messaging = FirebaseService.getMessaging();

      // get token and send to the server
      getToken(messaging)
        .then((currentToken) => {
          if (currentToken) {
            sendWebTokenAction(currentToken);
            setShouldShowMessage(false);
            if (onSuccess !== null) {
              onSuccess();
            }
          } else {
            setErrorMessage(getErrorMessage('messaging/token-failed', null));
          }
        })
        .catch((errorToken) => {
          setErrorMessage(
            getErrorMessage('messaging/token-failed', errorToken.message)
          );
        });
    }
  };

  const getErrorMessage = (code, message) => {
    switch (code) {
      case 'messaging/permission-blocked':
        return 'You have blocked Stampa to be able to send you notifications. Please use the browser settings to give your permission for the notifications.';
      case 'messaging/token-failed':
        return 'The process for giving your permission for the notifications has failed. Please try again to press the button and give your permission. If this error occurs again. Please contact Stampa.';
      default:
        return message;
    }
  };

  const renderErrorMessage = () => {
    if (errorMessage !== null) {
      return (
        <div style={{ color: 'red', marginBottom: 10 }}>{errorMessage}</div>
      );
    }
    return null;
  };

  if (shouldShowMessage === true && FirebaseService.isMessagingSupported()) {
    return (
      <Card style={{ marginTop: 10, marginBottom: 10 }}>
        <div style={{ padding: 20 }}>
          {renderErrorMessage()}
          <div style={{ marginBottom: 20 }}>
            In order to use this service effectively, you need to be able to
            receive notifications from Stampa. Please press the button below to
            give your permission.
          </div>
          <div>
            <CustomButton
              color="success"
              onClick={() => {
                getWebToken();
              }}
            >
              Give Notification Permission
            </CustomButton>
          </div>
        </div>
      </Card>
    );
  }

  return null;
};
