import { FirebaseService } from '../../services/Firebase';
import { ShopService } from '../../services/Shop';

import { throwExceptionIfErrorExists, getServerError } from '../../helpers';

import {
  GET_SHOPS_REQUEST,
  GET_SHOPS_SUCCESS,
  GET_SHOPS_FAIL,
} from '../types/shop';

export const getShopsAction = async (dispatch) => {
  dispatch({
    type: GET_SHOPS_REQUEST,
    payload: null,
  });

  try {
    // get the token from firebase
    const token = await FirebaseService.getUserToken();

    // get shops from API
    const apiResponse = await ShopService.getShops(token);
    throwExceptionIfErrorExists(apiResponse);

    dispatch({
      type: GET_SHOPS_SUCCESS,
      payload: {
        shops: apiResponse.data.data,
      },
    });
  } catch (e) {
    const error = getServerError(e.code, e.message);
    dispatch({
      type: GET_SHOPS_FAIL,
      payload: {
        hideContent: error.hideContent,
        errorMessage: error.errorMessage,
        closeMessage: error.closeMessage,
      },
    });
  }
};
