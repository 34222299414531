export const SUCCESS_GET_CODE = 200;
export const SUCCESS_CREATE_CODE = 201;
export const SUCCESS_UPDATE_CODE = 204;
export const CONFLICT_ERROR_CODE = 409;

export const HTTP_OK = 200;
export const HTTP_CREATED = 201;
export const HTTP_NOT_FOUND = 404;
export const HTTP_GONE = 410;
export const HTTP_NO_CONTENT = 204;
export const HTTP_PARTIAL_CONTENT = 206;

export const FIREBASE_LOGO_FOLDER = 'logos';

export const GOOGLE_RECAPTCHA_KEY_PROD =
  '6LdYRO0ZAAAAANDhwiy4Bz0Wb0moyJxw4cFrAs9O';
export const GOOGLE_RECAPTCHA_KEY_DEV =
  '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
