import React, { Fragment } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import RequireAuth from './RequireAuth';
import SignUpPage from '../pages/SignUp';
import LoginPage from '../pages/Login';
import ForgotPasswordPage from '../pages/ForgotPassword';
import VerifyEmailPage from '../pages/VerifyEmail';
import ResetPasswordPage from '../pages/ResetPassword';
import LogoutPage from '../pages/Logout';
import NotFound from '../pages/NotFound';
import dashboardRoutes from '../routes/dashboard.js';

const MainRouter = () => {
  let childrenArray = [];

  return (
    <BrowserRouter>
      <Fragment>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/verify-email" element={<VerifyEmailPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route path="/logout" element={<LogoutPage />} />
          {dashboardRoutes.map((prop, key) => {
            if (prop.children) {
              childrenArray = prop.children.map((item) => {
                return item;
              });
            }

            return (
              <Route
                path={prop.path}
                key={key}
                element={
                  <RequireAuth path={prop.path}>
                    <prop.component {...prop} />
                  </RequireAuth>
                }
              />
            );
          })}
          {childrenArray.map((prop, key) => {
            return (
              <Route
                path={prop.path}
                key={key}
                element={
                  <RequireAuth path={prop.path}>
                    <prop.component {...prop} />
                  </RequireAuth>
                }
              />
            );
          })}
          <Route
            path="/"
            element={<Navigate to="/dashboard" replace={true} />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Fragment>
    </BrowserRouter>
  );
};

export default MainRouter;
