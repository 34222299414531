import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import LoadingOverlay from 'react-loading-overlay-ts';
import HashLoader from 'react-spinners/HashLoader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { useShopsContext } from '../../../contexts/ShopsContext';
import { getShopsAction } from '../../../actions/shop/getShops';

import Card from '../../Card';
import CardHeader from '../../Card/Header';
import CardBody from '../../Card/Body';
import CardFooter from '../../Card/Footer';
import CardIcon from '../../Card/Icon';
import CustomButton from '../../CustomButton';
import FirebaseLogo from '../../FirebaseLogo';
import CustomSnackbarContent from '../../CustomSnackbarContent';

import styles from './styles';

import logo from '../../../assets/img/shop-logo.png';

const DashboardShop = ({ classes }) => {
  const [shopsState, shopsDispatch] = useShopsContext();

  useEffect(() => {
    getShopsAction(shopsDispatch);
  }, []);

  const renderStampOffer = () => {
    return (
      <React.Fragment>
        <ListItem>
          <ListItemText
            primary="Offer Description:"
            secondary={shopsState.list[0].offer.description}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={`Required number of stamps: ${shopsState.list[0].offer.num_stamp}`}
          />
        </ListItem>
      </React.Fragment>
    );
  };

  if (shopsState.error.exist) {
    return (
      <CustomSnackbarContent
        message={shopsState.error.message}
        open
        close={shopsState.error.closeMessage}
        color="danger"
      />
    );
  }

  if (shopsState.list.length === 0 && shopsState.processing === false) {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <p className={classes.cardBodyText}>
              {
                "It seems you haven't added a shop yet. Press the button below to add a shop."
              }
            </p>
          </CardBody>
          <CardFooter stats>
            <NavLink to="/add-shop" style={{ margin: 'auto' }}>
              <CustomButton color="success">Add Shop</CustomButton>
            </NavLink>
          </CardFooter>
        </Card>
      </React.Fragment>
    );
  }

  if (shopsState.list.length !== 0) {
    const shop = shopsState.list[0].shop;

    return (
      <React.Fragment>
        <LoadingOverlay
          active={shopsState.processing}
          spinner={<HashLoader color="#4caf50" />}
        >
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <FirebaseLogo defaultLogoUrl={logo} logoUrl={shop.logo} />
              </CardIcon>
              <h3 className={classes.h3ShopName}>{shop.name}</h3>
            </CardHeader>
            <CardBody>
              <List dense={false}>
                <ListItem>
                  <ListItemText
                    primary="Shop Description:"
                    secondary={shop.description}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Shop Address:"
                    secondary={`${shop.address}, ${shop.postcode}`}
                  />
                </ListItem>
                {renderStampOffer()}
              </List>
            </CardBody>
            <CardFooter stats>
              Want to generate stamps for your customers? Click the button below
              to get to the right page.
            </CardFooter>
            <CardFooter>
              <NavLink to="/generate-stamp" style={{ margin: 'auto' }}>
                <CustomButton color="success" size="sm">
                  Generate Stamps
                </CustomButton>
              </NavLink>
            </CardFooter>
          </Card>
        </LoadingOverlay>
      </React.Fragment>
    );
  }
};

export default withStyles(styles)(DashboardShop);
