import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import withStyles from '@material-ui/core/styles/withStyles';

import CustomButton from '../../components/CustomButton';
import CustomField from '../../components/CustomField';

import styles from './styles';

const LoginForm = ({ onSubmit, classes }) => {
  const [showPassword, setShowPassword] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="login-form">
      <CustomField
        name="email"
        control={control}
        errors={errors}
        rules={{
          required: 'Email Address is required',
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Invalid email address',
          },
        }}
        id="email"
        label="Email Address"
        type="email"
      />

      <CustomField
        name="password"
        control={control}
        errors={errors}
        rules={{
          required: 'Password is required',
        }}
        id="password"
        label="Password"
        type="password"
        showPassword={showPassword}
        handleClickShowPassword={handleClickShowPassword}
      />

      <CustomButton
        block
        color="success"
        className={classes.submitBtn}
        type="submit"
      >
        Login
      </CustomButton>
    </form>
  );
};

export default withStyles(styles)(LoginForm);
