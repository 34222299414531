import React from 'react';
import { useForm } from 'react-hook-form';
import withStyles from '@material-ui/core/styles/withStyles';

import GridItem from '../../../components/Grid/Item';
import GridContainer from '../../../components/Grid/Container';
import Card from '../../../components/Card';
import CardBody from '../../../components/Card/Body';
import CardFooter from '../../../components/Card/Footer';
import CustomField from '../../../components/CustomField';
import CustomButton from '../../../components/CustomButton';

import styles from './styles';

const EditStampOfferForm = ({ onSubmit, onCancel, initialValues, classes }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <CustomField
                name="description"
                control={control}
                errors={errors}
                rules={{
                  required: 'Description is required',
                }}
                id="reward-description"
                label="Description about the reward"
                type="text"
                defaultValue={initialValues.description}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <CustomField
                name="num_stamps"
                control={control}
                errors={errors}
                rules={{
                  required: 'Number of stamps is required',
                  pattern: {
                    value: /^[0-9]+$/,
                    message: 'Number of stamps should be a number',
                  },
                }}
                id="stamps-number"
                label="Number of stamps"
                type="number"
                defaultValue={initialValues.num_stamps}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <CustomField
                name="policy"
                control={control}
                id="reward-policy"
                label="Privacy policy for the reward (optional)"
                type="textarea"
                defaultValue={initialValues.policy}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
        <CardFooter>
          <div style={{ flex: 1 }}>
            <CustomButton
              color="success"
              type="submit"
              style={{ marginRight: 20 }}
            >
              Update
            </CustomButton>
            <CustomButton color="danger" onClick={() => onCancel()}>
              Cancel
            </CustomButton>
          </div>
        </CardFooter>
      </Card>
    </form>
  );
};

export default withStyles(styles)(EditStampOfferForm);
