import { FirebaseService } from '../../services/Firebase';
import { StampService } from '../../services/Stamp';

import { HTTP_NO_CONTENT } from '../../config';

export const initialState = {
  status: false,
  error: {
    exist: false,
    message: '',
  },
  processing: false,
};

export const removePermanentStamp = async (shopId, dispatch) => {
  dispatch({ ...initialState, processing: true });
  const genericMessage =
    'There was an error. Please try again later. If the error still exists, please contact support';

  try {
    // get the token from firebase
    const token = await FirebaseService.getUserToken();

    // get stamp from API
    const apiResponse = await StampService.removePermanentStamp(token, shopId);

    if (apiResponse.status === undefined) {
      dispatch({
        ...initialState,
        error: {
          exist: true,
          message: genericMessage,
        },
      });
    } else if (apiResponse.status !== HTTP_NO_CONTENT) {
      dispatch({
        ...initialState,
        error: {
          exist: true,
          message: apiResponse.data.message,
        },
      });
    } else {
      dispatch({
        ...initialState,
        status: true,
      });
    }
  } catch (e) {
    dispatch({
      ...initialState,
      error: {
        exist: true,
        message: genericMessage,
      },
    });
  }
};
