import React from 'react';
import { useForm } from 'react-hook-form';
import withStyles from '@material-ui/core/styles/withStyles';

import CustomButton from '../../components/CustomButton';
import CustomField from '../../components/CustomField';

import styles from './styles';

const ForgotPasswordForm = ({ onSubmit, classes }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CustomField
        name="email"
        control={control}
        errors={errors}
        rules={{
          required: 'Email Address is required',
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Invalid email address',
          },
        }}
        id="email"
        label="Email Address"
        type="text"
        autoComplete="off"
      />

      <CustomButton
        block
        color="success"
        className={classes.submitBtn}
        type="submit"
      >
        Reset Password
      </CustomButton>
    </form>
  );
};

export default withStyles(styles)(ForgotPasswordForm);
