import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';

import { FirebaseService } from '../../services/Firebase';

const FirebaseLogo = ({ defaultLogoUrl, logoUrl, ...rest }) => {
  const [firebaseLogoUrl, setFirebaseLogoUrl] = useState('');

  useEffect(() => {
    if (logoUrl !== '') {
      const fetchUrl = async () => {
        const url = await FirebaseService.getFileUrl(logoUrl);

        if (url !== undefined && !Object.prototype.hasOwnProperty.call(url, 'error')) {
          setFirebaseLogoUrl(url);
        }
      };

      fetchUrl();
    }
  }, []);

  if (firebaseLogoUrl !== '') {
    return <Avatar alt="Logo" src={firebaseLogoUrl} {...rest} />;
  }

  return <Avatar alt="Logo" src={defaultLogoUrl} {...rest} />;
};

export default FirebaseLogo;
