import React, { createContext, useContext, useReducer } from 'react';
import notificationsReducer, { initialState } from '../reducers/notifications';

const NotificationsContext = createContext([initialState, () => ({})]);

export const NotificationsProvider = ({ children }) => {
  const value = useReducer(notificationsReducer, initialState);
  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotificationsContext = () => useContext(NotificationsContext);
