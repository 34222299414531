import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import LoadingOverlay from 'react-loading-overlay-ts';
import HashLoader from 'react-spinners/HashLoader';

import { useUserContext } from '../../contexts/UserContext';

import CustomSnackbarContent from '../../components/CustomSnackbarContent';

import LoginForm from '../../forms/Login';

import stampaLogo from '../../assets/img/logo.png';

import { loginUserAction } from '../../actions/user/loginUser';

import { sendWebTokenForPermissionGrantedAction } from '../../actions/webToken/sendWebTokenForPermissionGranted';

import styles from './styles';

import { CLEAR_ERROR } from '../../actions/types/user';

const LoginPage = ({ classes }) => {
  const [userState, userDispatch] = useUserContext();

  useEffect(() => {
    return () => {
      userDispatch({
        type: CLEAR_ERROR,
        payload: null,
      });
    };
  }, []);

  const onLoginEmailAccountRequest = (formValues) => {
    loginUserAction(formValues, userDispatch);
  };

  const showErrorMessage = () => {
    if (userState.error.exist) {
      return (
        <div style={{ marginTop: 20 }}>
          <CustomSnackbarContent
            message={userState.error.message}
            close
            open
            color="danger"
          />
        </div>
      );
    }

    return null;
  };

  if (userState.user.exist) {
    if (userState.user.verified) {
      //sendWebTokenForPermissionGrantedAction();

      return <Navigate to="/dashboard" replace={true} />;
    }

    if (!userState.user.verified) {
      return <Navigate to="/verify-email" replace={true} />;
    }
  }

  return (
    <main className={classes.main}>
      <LoadingOverlay
        active={userState.processing}
        spinner={<HashLoader color="#4caf50" />}
      >
        <CssBaseline />
        <Paper className={classes.paper}>
          <Avatar alt="Stampa" src={stampaLogo} className={classes.avatar} />

          <Typography component="h1" variant="h5" className={classes.h1}>
            Login
          </Typography>
          {showErrorMessage()}
          <LoginForm onSubmit={onLoginEmailAccountRequest} />
          <div style={{ marginTop: 20 }}>
            {`Don't`} you have an account?{' '}
            <Link to="/signup" className={classes.links}>
              Sign Up
            </Link>
            .
          </div>
          <div style={{ marginTop: 10 }}>
            Forgot your password? Click{' '}
            <Link to="/forgot-password" className={classes.links}>
              here
            </Link>
            .
          </div>
        </Paper>
      </LoadingOverlay>
    </main>
  );
};

export default withStyles(styles)(LoginPage);
