import axios from 'axios';

const rewardsEndPoint = process.env.REACT_APP_API_URI + 'rewards';

export const RewardService = {
  async updateRewardStatus(token, id, status) {
    return axios({
      method: 'put',
      url: `${rewardsEndPoint}/${id}`,
      timeout: process.env.REACT_APP_API_REQUEST_TIMEOUT,
      headers: {
        Authorization: token,
      },
      data: {
        status,
      },
    })
      .then((response) => response)
      .catch((error) => error.response);
  },
};
