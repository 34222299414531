import React, { useEffect, useState, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import _ from 'lodash';
import withStyles from '@material-ui/core/styles/withStyles';
import LoadingOverlay from 'react-loading-overlay-ts';
import HashLoader from 'react-spinners/HashLoader';
import QRCode from 'qrcode.react';
import { Grid } from '@material-ui/core';

import { useShopsContext } from '../../../contexts/ShopsContext';
import { getShopsAction } from '../../../actions/shop/getShops';

import Card from '../../Card';
import CardHeader from '../../Card/Header';
import CardBody from '../../Card/Body';
import CardFooter from '../../Card/Footer';
import CustomButton from '../../CustomButton';

import styles from './styles';

import stampaLogo from '../../../assets/img/logo.png';

import {
  generatePermanentStamp,
  initialState as generatePermanentStampInitialState,
} from '../../../actions/stamp/generatePermanentStamp';

import {
  removePermanentStamp,
  initialState as removePermanentStampInitialState,
} from '../../../actions/stamp/removePermanentStamp';

const DashboardPermanentStamp = ({ classes }) => {
  const [shopsState, shopsDispatch] = useShopsContext();
  const [generatePermanentStampStatus, setGeneratePermanentStampStatus] =
    useState(generatePermanentStampInitialState);
  const [removePermanentStampStatus, setRemovePermanentStampStatus] = useState(
    removePermanentStampInitialState
  );
  const ref = useRef(null);

  useEffect(() => {
    getShopsAction(shopsDispatch);
  }, []);

  useEffect(() => {
    getShopsAction(shopsDispatch);
  }, [generatePermanentStampStatus]);

  useEffect(() => {
    getShopsAction(shopsDispatch);
  }, [removePermanentStampStatus]);

  const hasPermanentStamp = () => {
    return (
      shopsState.list.length !== 0 &&
      !_.isEmpty(shopsState.list[0].shop.permanent_stamp)
    );
  };

  const getShopId = () => {
    if (
      shopsState.list.length === 0 ||
      !_.isEmpty(shopsState.list[0].shop.id)
    ) {
      return null;
    }

    return shopsState.list[0].shop.id;
  };

  const generatePermanentStampRequest = () => {
    const shopId = getShopId();
    if (typeof shopId !== 'number') {
      alert(
        'There might be an issue going on. Please refresh the page and try again.'
      );
    } else {
      generatePermanentStamp(shopId, setGeneratePermanentStampStatus);
    }
  };

  const regeneratePermanentStampRequest = () => {
    if (
      window.confirm(
        'Are you sure you want to regenerate your permanent stamp?'
      )
    ) {
      generatePermanentStampRequest();
    }
  };

  const removePermanentStampRequest = () => {
    if (
      window.confirm('Are you sure you want to remove your permanent stamp?')
    ) {
      const shopId = getShopId();
      if (typeof shopId !== 'number') {
        alert(
          'There might be an issue going on. Please refresh the page and try again.'
        );
      } else {
        removePermanentStamp(shopId, setRemovePermanentStampStatus);
      }
    }
  };

  const getPrintOption = () => {
    if (hasPermanentStamp()) {
      return (
        <ReactToPrint
          bodyClass="print-agreement"
          content={() => ref.current}
          trigger={() => (
            <i className={`material-icons ${classes.printIcon}`}>print</i>
          )}
        />
      );
    }
    return null;
  };

  const getBody = () => {
    if (hasPermanentStamp()) {
      return (
        <React.Fragment>
          <div style={{ display: 'none' }}>
            <div ref={ref}>
              <div className={classes.printedCard}>
                <div style={{ width: '100%' }}>
                  <div className={classes.printedQrCodeDiv}>
                    <QRCode
                      value={shopsState.list[0].shop.permanent_stamp}
                      size={100}
                      level={'H'}
                      includeMargin={true}
                      className={classes.printedQrCode}
                    />
                  </div>
                  <div className={classes.printedRightContainer}>
                    <p className={classes.printedRightP1}>
                      This is a permanent stamp.
                    </p>
                    <p className={classes.printedRightP2}>
                      Please be cautious by exposing to the public. More than
                      one stamp can be collected using this stamp! You can
                      always regenerate a new one in your business dashboard.
                    </p>
                  </div>
                  <div style={{ clear: 'both' }}></div>
                </div>
                <div className={classes.printedFooter}>
                  <div className={classes.printedFooterLeft}>
                    Powered by Stampa
                  </div>
                  <div className={classes.printedFooterRight}>
                    <img src={stampaLogo} width={20} />
                  </div>
                  <div style={{ clear: 'both' }}></div>
                </div>
              </div>
            </div>
          </div>
          <p className={classes.pStyle}>
            This is the permanent stamp (QR Code) that your customers can scan
            and collect stamps.
          </p>
          <div style={{ textAlign: 'center', alignItems: 'center' }}>
            <QRCode
              value={shopsState.list[0].shop.permanent_stamp}
              size={270}
              level={'H'}
              includeMargin={true}
            />
          </div>
          <p className={classes.pStyle}>
            Be cautious regarding sharing this as this is a permanent stamp and
            it does not expired. Although, you can always re-generate a new one
            and disable the current one.
          </p>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <p className={classes.pStyle}>
          There is no a permanent stamp generated. If you want to generate one,
          click the button below.
        </p>
      </React.Fragment>
    );
  };

  const getFooter = () => {
    if (hasPermanentStamp()) {
      return (
        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <CustomButton
              color="success"
              size="sm"
              style={{ margin: 'auto' }}
              onClick={() => {
                regeneratePermanentStampRequest();
              }}
            >
              Regenerate Permanent Stamp
            </CustomButton>
          </Grid>
          <Grid item>
            <CustomButton
              color="danger"
              size="sm"
              style={{ margin: 'auto' }}
              onClick={() => {
                removePermanentStampRequest();
              }}
            >
              Delete Permanent Stamp
            </CustomButton>
          </Grid>
        </Grid>
      );
    }

    return (
      <React.Fragment>
        <CustomButton
          color="success"
          size="sm"
          style={{ margin: 'auto' }}
          onClick={() => {
            generatePermanentStampRequest();
          }}
        >
          Generate Permanent Stamp
        </CustomButton>
      </React.Fragment>
    );
  };

  const getProcessingStatus = () => {
    return (
      shopsState.processing ||
      generatePermanentStampStatus.processing ||
      removePermanentStampStatus.processing
    );
  };

  if (shopsState.list.length === 0) {
    return null;
  }

  return (
    <React.Fragment>
      <LoadingOverlay
        active={getProcessingStatus()}
        spinner={<HashLoader color="#4caf50" />}
      >
        <Card>
          <CardHeader className={classes.greyTextColor} color="success" icon>
            <div style={{ width: '100%' }}>
              <div style={{ float: 'left' }}>
                <h3 className={classes.h3Header}>Permanent Stamp</h3>
              </div>
              <div style={{ float: 'right' }}>{getPrintOption()}</div>
              <div style={{ clear: 'both' }}></div>
            </div>
          </CardHeader>
          <CardBody>{getBody()}</CardBody>
          <CardFooter style={{ paddingBottom: 10 }}>{getFooter()}</CardFooter>
        </Card>
      </LoadingOverlay>
    </React.Fragment>
  );
};

export default withStyles(styles)(DashboardPermanentStamp);
