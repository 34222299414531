import {
  GET_SHOPS_REQUEST,
  GET_SHOPS_SUCCESS,
  GET_SHOPS_FAIL,
} from '../actions/types/shop';

export const initialState = {
  list: [],
  error: {
    exist: false,
    hideContent: false,
    closeMessage: true,
    message: '',
  },
  processing: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SHOPS_REQUEST:
      return {
        ...state,
        processing: true,
      };
    case GET_SHOPS_SUCCESS:
      return {
        ...initialState,
        list: action.payload.shops,
      };
    case GET_SHOPS_FAIL:
      return {
        ...initialState,
        error: {
          exist: true,
          closeMessage: action.payload.closeMessage,
          hideContent: action.payload.hideContent,
          message: action.payload.errorMessage,
        },
      };
    default:
      return {
        ...state,
        error: {
          exist: false,
          message: '',
          hideContent: false,
          closeMessage: true,
        },
      };
  }
}
