import React from 'react';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import GridItem from '../../components/Grid/Item';
import GridContainer from '../../components/Grid/Container';
import Card from '../../components/Card';
import CardHeader from '../../components/Card/Header';
import CardBody from '../../components/Card/Body';

const NotFound = ({ classes }) => {
  return (
    <div style={{ padding: 50 }}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="danger">
              <h4>Not Found</h4>
            </CardHeader>
            <CardBody>
              <p>
                The page you are looking for has not been found. Please click{' '}
                <a href="/">here</a> to go to the dashboard.
              </p>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default NotFound;
