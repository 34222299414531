import React, { useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import HashLoader from 'react-spinners/HashLoader';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CustomSnackbarContent from '../CustomSnackbarContent';

import CustomButton from '../CustomButton';

import {
  getSubscriptionAction,
  getCheckoutSessionUrl,
  subscriptionInitialState,
  redirectLinkInitialState,
  SUBSCRIBE_ACTION,
  REVIEW_SUBSCRIPTION_ACTION,
} from '../../actions/subscription/getSubscription';

import {
  getBillingUrl,
  billingLinkInitialState,
} from '../../actions/subscription/getBillingUrl';

export const SubscriptionMessage = () => {
  const [subscription, setSubscription] = useState(subscriptionInitialState);
  const [redirectUrl, setRedirectUrl] = useState(redirectLinkInitialState);
  const [billingState, setBillingState] = useState(billingLinkInitialState);

  useEffect(() => {
    // send request to find out the subscription status
    getSubscriptionAction(setSubscription);
  }, []);

  useEffect(() => {
    if (billingState.url) {
      window.location.href = billingState.url;
    }
  }, [billingState]);

  useEffect(() => {
    if (redirectUrl.url !== null) {
      window.location.replace(redirectUrl.url);
    }
  }, [redirectUrl]);

  const renderErrorMessage = () => {
    if (redirectUrl.errorMessage) {
      return (
        <div style={{ marginBottom: 10 }}>
          <CustomSnackbarContent
            message={redirectUrl.errorMessage}
            close
            open
            color="danger"
          />
        </div>
      );
    }
  };

  const button = () => {
    if (!subscription.showSubscribeButton || !subscription.buttonText) {
      return null;
    }

    return (
      <CustomButton
        size="sm"
        color="success"
        onClick={() => {
          if (subscription.subscribeButtonAction === SUBSCRIBE_ACTION) {
            getCheckoutSessionUrl(setRedirectUrl, {
              subscription_id: subscription.subscriptionId,
              success_url: `${window.location.origin}/success-subscription`,
              cancel_url: `${window.location.origin}/failed-subscription`,
            });
          }
          if (
            subscription.subscribeButtonAction === REVIEW_SUBSCRIPTION_ACTION
          ) {
            getBillingUrl(setBillingState, {
              return_url: window.location.href,
            });
          }
        }}
      >
        {subscription.buttonText}
      </CustomButton>
    );
  };

  if (!subscription.received) {
    return null;
  }

  if (!subscription.showSubscriptionMessage) {
    return null;
  }

  return (
    <div style={{ padding: 20, marginTop: 40 }}>
      <LoadingOverlay
        active={redirectUrl.processing}
        spinner={<HashLoader color="#4caf50" />}
      >
        {renderErrorMessage()}
        <SnackbarContent
          message={subscription.subscriptionMessage}
          action={button()}
        />
      </LoadingOverlay>
    </div>
  );
};
