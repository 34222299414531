import axios from 'axios';
import { AppErrorFormat } from '../helpers';

const stampOffersEndPoint = `${process.env.REACT_APP_API_URI}shops/{shopId}/stamp-offers`;

export const StampOfferService = {
  /**
   * Get stamp offers.
   *
   * @param {string} token
   * @param {string} shopId
   *
   * @returns {Array}
   */
  async getStampOffers(token, shopId) {
    return axios({
      method: 'get',
      url: stampOffersEndPoint.replace('{shopId}', shopId),
      timeout: process.env.REACT_APP_API_REQUEST_TIMEOUT,
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response)
      .catch((error) =>
        AppErrorFormat(error.response.status, error.response.data)
      );
  },

  async updateStampOffer(token, shopId, stampOfferId, stampOfferDetails) {
    return axios({
      method: 'put',
      url: `${stampOffersEndPoint.replace('{shopId}', shopId)}/${stampOfferId}`,
      timeout: process.env.REACT_APP_API_REQUEST_TIMEOUT,
      headers: {
        Authorization: token,
      },
      data: {
        shop_id: shopId,
        description: stampOfferDetails.description,
        num_stamps: stampOfferDetails.num_stamps,
        policy: stampOfferDetails.policy,
      },
    })
      .then((response) => response)
      .catch((error) =>
        AppErrorFormat(error.response.status, error.response.data)
      );
  },
};
