import moment from 'moment';

import { FirebaseService } from '../../services/Firebase';
import { SubscriptionService } from '../../services/Subscription';

import { HTTP_OK, HTTP_NOT_FOUND, HTTP_GONE, HTTP_CREATED } from '../../config';

export const subscriptionInitialState = {
  received: false,
  showMessage: false,
  subscriptionMessage: null,
  showSubscriptionMessage: false,
  showSubscribeButton: false,
  buttonText: null,
  subscribeButtonAction: null,
  subscriptionId: null,
};

export const redirectLinkInitialState = {
  processing: false,
  url: null,
  errorMessage: null,
};

export const SUBSCRIBE_ACTION = 'subscribe';
export const REVIEW_SUBSCRIPTION_ACTION = 'renew_subscription';

export const getSubscriptionAction = async (dispatch) => {
  try {
    // get the token from firebase
    const token = await FirebaseService.getUserToken();

    // get subscription from API
    const apiResponse = await SubscriptionService.getSubscription(token);

    if (apiResponse.status === HTTP_OK) {
      if (apiResponse.data.data.trial) {
        const endDate = moment.unix(apiResponse.data.data.end).format('L');

        dispatch({
          ...subscriptionInitialState,
          received: true,
          subscriptionMessage: `Your are on a free trial subscription that ends on ${endDate}. Please subscribe to avoid any service interruption.`,
          showSubscriptionMessage: true,
          showSubscribeButton: true,
          subscribeButtonAction: SUBSCRIBE_ACTION,
          buttonText: 'Subscribe',
          subscriptionId: apiResponse.data.data.subscription_id,
        });
      } else if (apiResponse.data.data.cancel_due) {
        const cancelDue = moment
          .unix(apiResponse.data.data.cancel_due)
          .format('L');

        dispatch({
          ...subscriptionInitialState,
          received: true,
          subscriptionMessage: `It seems your subscription have been canceled and the last day is ${cancelDue}. Please renew your subscription to avoid any service interruption.`,
          showSubscriptionMessage: true,
          showSubscribeButton: true,
          subscribeButtonAction: REVIEW_SUBSCRIPTION_ACTION,
          buttonText: 'Renew Subscription',
        });
      } else {
        dispatch({
          ...subscriptionInitialState,
          received: true,
        });
      }
    } else if (apiResponse.status === HTTP_NOT_FOUND) {
      dispatch({
        ...subscriptionInitialState,
        received: true,
        subscriptionMessage: apiResponse.data.message,
        showSubscriptionMessage: true,
      });
    } else if (apiResponse.status === HTTP_GONE) {
      dispatch({
        ...subscriptionInitialState,
        received: true,
        subscriptionMessage: apiResponse.data.message,
        showSubscriptionMessage: true,
        showSubscribeButton: true,
        subscribeButtonAction: SUBSCRIBE_ACTION,
        buttonText: 'Subscribe',
        subscriptionId: apiResponse.data.data.subscription_id,
      });
    } else {
      dispatch({
        ...subscriptionInitialState,
        received: true,
        subscriptionMessage: apiResponse.data.message,
        showSubscriptionMessage: true,
      });
    }
  } catch (error) {
    dispatch({
      ...subscriptionInitialState,
      received: true,
      showSubscriptionMessage: false,
    });
  }
};

export const getCheckoutSessionUrl = async (dispatch, payload) => {
  dispatch({
    ...redirectLinkInitialState,
    processing: true,
  });

  try {
    // get the token from firebase
    const token = await FirebaseService.getUserToken();

    // get subscription from API
    const apiResponse = await SubscriptionService.getCheckoutSessionUrl(
      token,
      payload
    );

    if (apiResponse === undefined) {
      dispatch({
        ...redirectLinkInitialState,
        errorMessage: 'There is an error. Please try again later.',
      });
    } else if (apiResponse.status === HTTP_CREATED) {
      dispatch({
        ...redirectLinkInitialState,
        url: apiResponse.data.data.checkout_url,
      });
    } else {
      dispatch({
        ...redirectLinkInitialState,
        errorMessage: apiResponse.data.message,
      });
    }
  } catch (error) {
    dispatch({
      ...redirectLinkInitialState,
      errorMessage: error.message,
    });
  }
};
