/* eslint-disable no-undef */
import { initializeApp } from 'firebase/app';
import {
  fetchSignInMethodsForEmail,
  updateProfile,
  onAuthStateChanged,
  signInWithCredential,
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithRedirect,
  getRedirectResult,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  deleteUser,
  signOut,
  getAuth,
  sendPasswordResetEmail,
} from 'firebase/auth';
import {
  getStorage,
  ref,
  getDownloadURL,
  deleteObject,
  uploadBytes,
} from 'firebase/storage';
import {
  getMessaging,
  isSupported as messagingIsSupported,
} from 'firebase/messaging';
import { AppErrorFormat } from '../helpers';

let app = null;
let auth = null;

export const FirebaseService = {
  /**
   * initialize firebase connection if it doesn't already exist.
   */
  init() {
    if (app === null) {
      app = initializeApp({
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_MESSAGING_APP_ID,
      });

      if (auth === null) {
        auth = getAuth(app);
      }
    }

    return true;
  },

  getMessaging() {
    try {
      return getMessaging(app);
    } catch (e) {
      // TODO: log the error for unsupported browser
      return null;
    }
  },

  isMessagingSupported() {
    return messagingIsSupported();
  },

  /**
   * Get an array of providers that the user has linked
   * and being able to authenticate in firebase.
   *
   * @param {string} email
   *
   * @return {array.<string>}
   */
  getProvidersByEmail(email) {
    return fetchSignInMethodsForEmail(auth, email)
      .then((response) => response)
      .catch((error) => AppErrorFormat(error.code, error.message));
  },

  /**
   * Update the user details with the submitted ones.
   *
   * @param {object} userDetails
   *
   * @returns {object}
   */
  async updateUser(userDetails) {
    return updateProfile(auth.currentUser, userDetails)
      .then((response) => response)
      .catch((error) => AppErrorFormat(error.code, error.message));
  },

  /**
   * Get user token
   *
   * @returns {object}
   */
  async getUserToken() {
    return new Promise((resolve, reject) => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          user
            .getIdToken()
            .then((response) => {
              resolve(response);
            })
            .catch((error) => AppErrorFormat(error.code, error.message));
        } else {
          reject(new Error('Ops!'));
        }
      });
    });
  },

  /**
   * Create a user account in firebase if it doesn't already exist
   * by providing the credential from a provider.
   *
   * This method returns a user object.
   *
   * @param {Object} credential
   *
   * @returns {object}
   */
  signInUserWithCredential(credential) {
    return signInWithCredential(auth, credential)
      .then((response) => ({
        name: response.user.displayName,
        email: response.user.email,
        verified: response.user.emailVerified,
        uid: response.user.uid,
      }))
      .catch((error) => AppErrorFormat(error.code, error.message));
  },

  /**
   * Get credential using the FacebookAuthProvider from firebase
   * to use the credential for creating the firebase account.
   *
   * @param {string} token
   *
   * @returns {object}
   */
  getCredentialFromFacebook(token) {
    return FacebookAuthProvider.credential(token);
  },

  /**
   * Get credential using the FacebookAuthProvider from firebase
   * to use the credential for creating the firebase account.
   *
   * @param {string} idToken
   * @param {string} accessToken
   *
   * @returns {object}
   */
  getCredentialFromGoogle(idToken, accessToken) {
    return GoogleAuthProvider.credential(idToken, accessToken);
  },

  /**
   * Login user in firebase.
   *
   * @param {object} user
   *
   * @returns {object}
   */
  async login(user) {
    return signInWithEmailAndPassword(auth, user.email, user.password)
      .then((response) => ({
        name: response.user.displayName,
        email: response.user.email,
        verified: response.user.emailVerified,
        uid: response.user.uid,
      }))
      .catch((error) => AppErrorFormat(error.code, error.message));
  },

  /**
   * Login user with Google.
   */
  loginWithGoogle() {
    const provider = new GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    signInWithRedirect(auth, provider);
  },

  /**
   * Login user with Google.
   */
  loginWithFacebook() {
    const provider = new FacebookAuthProvider();
    provider.addScope('email');
    signInWithRedirect(auth, provider);
  },

  /**
   * Login user with Google.
   *
   */
  getSocialRedirectResult() {
    return getRedirectResult(auth)
      .then((response) => response)
      .catch((error) => AppErrorFormat(error.code, error.message));
  },

  /**
   * Create user in firebase.
   *
   * @param {object} user
   *
   * @returns {object}
   */
  async createUser(user) {
    return createUserWithEmailAndPassword(auth, user.email, user.password)
      .then((response) => ({
        email: response.user.email,
        verified: response.user.emailVerified,
        uid: response.user.uid,
      }))
      .catch((error) => AppErrorFormat(error.code, error.message));
  },

  /**
   * Send an email to the user to verifiy the email.
   *
   * @returns {object}
   */
  async sendUserEmailVerification() {
    return sendEmailVerification(auth.currentUser)
      .then(() => ({ sent: true }))
      .catch((error) => AppErrorFormat(error.code, error.message));
  },

  /**
   * Delete firebase user.
   *
   * @returns {object}
   */
  async removeUser() {
    return deleteUser(auth.currentUser)
      .then((response) => response)
      .catch((error) => AppErrorFormat(error.code, error.message));
  },

  /**
   * Logout user from firebase.
   *
   * @returns {object}
   */
  logoutUser() {
    return signOut(auth)
      .then((response) => response)
      .catch((error) => AppErrorFormat(error.code, error.message));
  },

  /**
   * Reset user password from firebase.
   *
   * @param {string} email
   *
   * @returns {object}
   */
  async resetPassword(email) {
    // return app
    //   .auth()
    //   .sendPasswordResetEmail(email)
    //   .then((response) => response)
    //   .catch((error) => AppErrorFormat(error.code, error.message));
    return sendPasswordResetEmail(auth, email)
      .then((response) => response)
      .catch((error) => AppErrorFormat(error.code, error.message));
  },

  /**
   * Upload file
   *
   * @param {object} file
   * @param {string} folder
   *
   * @returns {object}
   */
  async uploadFile(file, folder) {
    const timestamp = Date.now();
    const random = Math.random().toString(36).substring(2);
    const filename = random + timestamp + '.' + file.type.split('/')[1];

    const storage = getStorage();
    const storageRef = ref(storage, `${folder}/${filename}`);
    //const mountainsRef = storageRef.child(`${folder}/${filename}`);

    return uploadBytes(storageRef, file)
      .then((snapshot) => snapshot)
      .catch((error) => AppErrorFormat(error.code, error.message));

    // return mountainsRef
    //   .put(file)
    //   .then((snapshot) => snapshot)
    //   .catch((error) => AppErrorFormat(error.code, error.message));
  },

  /**
   * Return file url
   *
   * @param {string} filePath
   *
   * @returns {string}
   */
  async getFileUrl(filePath) {
    const storage = getStorage();
    return getDownloadURL(ref(storage, filePath))
      .then((url) => url)
      .catch((error) => AppErrorFormat(error.code, error.message));
  },

  /**
   * Delete file
   *
   * @param {string} filePath
   */
  async deleteFile(filePath) {
    const storage = getStorage();
    return deleteObject(ref(storage, filePath))
      .then(() => 'File deleted successfully')
      .catch((error) => AppErrorFormat(error.code, error.message));
  },
};
