import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import LoadingOverlay from 'react-loading-overlay-ts';
import HashLoader from 'react-spinners/HashLoader';

import { useUserContext } from '../../contexts/UserContext';

import CustomSnackbarContent from '../../components/CustomSnackbarContent';

import ForgotPasswordForm from '../../forms/ForgotPassword';

import stampaLogo from '../../assets/img/logo.png';

import { resetPasswordAction } from '../../actions/user/resetPassword';

import styles from './styles';

import { CLEAR_ERROR } from '../../actions/types/user';

const ForgotPasswordPage = ({ classes }) => {
  const [userState, userDispatch] = useUserContext();

  useEffect(() => {
    return () => {
      userDispatch({
        type: CLEAR_ERROR,
        payload: null,
      });
    };
  }, []);

  const showErrorMessage = () => {
    if (userState.error.exist) {
      return (
        <div style={{ marginTop: 20 }}>
          <CustomSnackbarContent
            message={userState.error.message}
            close
            open
            color="danger"
          />
        </div>
      );
    }

    return null;
  };

  const onResetPasswordRequest = (formValues) => {
    resetPasswordAction(formValues.email, userDispatch);
  };

  if (userState.user.resetPassword) {
    return <Navigate to="/reset-password" replace={true} />;
  }

  return (
    <main className={classes.main}>
      <LoadingOverlay
        active={userState.processing}
        spinner={<HashLoader color="#4caf50" />}
      >
        <CssBaseline />
        <Paper className={classes.paper}>
          <Avatar alt="Stampa" src={stampaLogo} className={classes.avatar} />

          <Typography component="h1" variant="h5" className={classes.h1}>
            Reset My Password
          </Typography>

          {showErrorMessage()}
          <ForgotPasswordForm onSubmit={onResetPasswordRequest} />
        </Paper>
      </LoadingOverlay>
    </main>
  );
};

export default withStyles(styles)(ForgotPasswordPage);
