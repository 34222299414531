import { FirebaseService } from '../../services/Firebase';
import { UserService } from '../../services/User';
import {
  AppErrors,
  throwExceptionIfErrorExists,
  throwExceptionForMissingUser,
} from '../../helpers';

import {
  PASSWORD_SIGN_UP_REQUEST,
  PASSWORD_SIGN_UP_SUCCESS,
  PASSWORD_SIGN_UP_FAIL,
} from '../types/user';

export const createUserAction = async (payload, dispatch) => {
  dispatch({
    type: PASSWORD_SIGN_UP_REQUEST,
    payload: null,
  });

  try {
    // create user in firebase
    const user = await FirebaseService.createUser(payload);
    throwExceptionIfErrorExists(user);

    // update user displayName
    await FirebaseService.updateUser({ displayName: payload.name });

    // get the token from firebase
    const token = await FirebaseService.getUserToken();

    // if token doesn't exist then delete the user in firebase
    if (Object.prototype.hasOwnProperty.call(token, 'error')) {
      const firebaseResponse = await FirebaseService.removeUser();
      throwExceptionForMissingUser(firebaseResponse);
    }

    // create a user in API
    const apiResponse = await UserService.create(token, payload.subscription);

    // if user is not created then we ask user to sign up again
    if (Object.prototype.hasOwnProperty.call(apiResponse, 'error')) {
      // delete user from firebase
      const firebaseResponse = await FirebaseService.removeUser();
      throwExceptionForMissingUser(firebaseResponse);
    } else {
      // send verification email to user
      const emailVerificationResponse =
        await FirebaseService.sendUserEmailVerification();

      // if verification email is not sent then we ask user to sign in again
      if (
        Object.prototype.hasOwnProperty.call(emailVerificationResponse, 'error')
      ) {
        //@todo: check if we have to delete the user in our API
        //const apiResponse = yield call(UserService.delete, token);

        // delete user from firebase
        const firebaseResponse = await FirebaseService.removeUser();
        throwExceptionForMissingUser(firebaseResponse);
      }
    }

    dispatch({
      type: PASSWORD_SIGN_UP_SUCCESS,
      payload: {
        user: {
          name: payload.name,
          email: user.email,
          verified: false,
          exist: true,
        },
      },
    });
  } catch (e) {
    dispatch({
      type: PASSWORD_SIGN_UP_FAIL,
      payload: {
        errorMessage: AppErrors(e.errorCode, e.errorMessage),
      },
    });
  }
};
