import {
  PASSWORD_SIGN_UP_REQUEST,
  PASSWORD_SIGN_UP_SUCCESS,
  PASSWORD_SIGN_UP_FAIL,
  PASSWORD_LOGIN_REQUEST,
  PASSWORD_LOGIN_SUCCESS,
  PASSWORD_LOGIN_FAIL,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  CLEAR_ERROR,
  CLEAR_AUTH_STATE_REQUEST,
} from '../actions/types/user';

export const initialState = {
  user: {
    name: '',
    email: '',
    verified: false,
    exist: false,
    resetPassword: false,
  },
  error: {
    exist: false,
    message: '',
  },
  processing: false,
};

export default function (state, action) {
  switch (action.type) {
    case PASSWORD_SIGN_UP_REQUEST:
    case PASSWORD_LOGIN_REQUEST:
    case LOGOUT_REQUEST:
    case RESET_PASSWORD_REQUEST:
      return {
        ...initialState,
        processing: true,
      };
    case PASSWORD_SIGN_UP_SUCCESS:
    case PASSWORD_LOGIN_SUCCESS:
      return {
        ...initialState,
        user: action.payload.user,
      };
    case PASSWORD_SIGN_UP_FAIL:
    case PASSWORD_LOGIN_FAIL:
    case LOGOUT_FAIL:
    case RESET_PASSWORD_FAIL:
      return {
        ...initialState,
        error: {
          exist: true,
          message: action.payload.errorMessage,
        },
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...initialState,
        user: {
          resetPassword: true,
        },
      };
    case LOGOUT_SUCCESS:
    case CLEAR_AUTH_STATE_REQUEST:
      return initialState;
    case CLEAR_ERROR:
      return {
        ...state,
        error: initialState.error,
      };
    default:
      return state;
  }
}
