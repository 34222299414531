import { FirebaseService } from '../../services/Firebase';
import { ShopService } from '../../services/Shop';

import { throwExceptionIfErrorExists, ServerError } from '../../helpers';

import { FIREBASE_LOGO_FOLDER } from '../../config';

export const initialState = {
  shop: {},
  error: {
    exist: false,
    message: '',
  },
  processing: false,
};

export const createShopAction = async (shopDetails, dispatch) => {
  dispatch({ ...initialState, processing: true });

  try {
    shopDetails.business_logo = '';

    // get the token from firebase
    const token = await FirebaseService.getUserToken();

    // upload file if exists
    if (shopDetails.file !== null) {
      const uploadFileResponse = await FirebaseService.uploadFile(
        shopDetails.file,
        FIREBASE_LOGO_FOLDER
      );

      if (
        uploadFileResponse !== undefined &&
        !Object.prototype.hasOwnProperty.call(uploadFileResponse, 'error')
      ) {
        shopDetails.business_logo = uploadFileResponse.ref.fullPath;
      }
    }

    // create shop
    const apiResponse = await ShopService.createShop(token, shopDetails);
    throwExceptionIfErrorExists(apiResponse);

    dispatch({ ...initialState, shop: apiResponse.data.data });
  } catch (e) {
    if (shopDetails.business_logo !== '') {
      // delete logo on firebase
      FirebaseService.deleteFile(shopDetails.business_logo);
    }

    dispatch({
      ...initialState,
      error: {
        exist: true,
        message: ServerError(e.code, e.message),
      },
    });
  }
};
