export default (theme) => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
    backgroundColor: '#fff',
    marginTop: 30,
    marginBottom: 30,
  },
  avatar: {
    margin: theme.spacing(1),
    width: 80,
    height: 80,
  },
  h1: {
    color: '#747474',
    marginTop: 10,
  },
  textSeparator: {
    marginTop: 30,
    color: '#333',
  },
  signUpBtn: {
    marginTop: 20,
    fontWeight: 'bold',
  },
  TCs: {
    marginTop: 20,
    fontSize: 12,
    color: '#333',
    textAlign: 'center',
  },
  links: {
    color: '#008000',
    textDecorationLine: 'none',
    '&:hover': {
      color: '#008000',
      textDecorationLine: 'underline',
    },
    '&:visited': {
      color: '#008000',
    },
  },
});
