import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import { useShopsContext } from '../../contexts/ShopsContext';
import GridItem from '../../components/Grid/Item';
import GridContainer from '../../components/Grid/Container';
import CustomTable from '../../components/CustomTable';
import Card from '../../components/Card';
import CardHeader from '../../components/Card/Header';
import CardBody from '../../components/Card/Body';
import LoadingOverlay from 'react-loading-overlay-ts';
import HashLoader from 'react-spinners/HashLoader';
import CustomButton from '../../components/CustomButton';
import CustomSnackbarContent from '../../components/CustomSnackbarContent';
import FirebaseLogo from '../../components/FirebaseLogo';

import styles from './styles';
import logo from '../../assets/img/shop-logo.png';

import { getShopsAction } from '../../actions/shop/getShops';

const TABLE_HEADER = ['Logo', 'Name', 'Address', ''];

const Shops = () => {
  const [shopsState, shopsDispatch] = useShopsContext();

  useEffect(() => {
    getShopsAction(shopsDispatch);
  }, []);

  const showErrorMessage = () => {
    if (shopsState.error.exist) {
      return (
        <div style={{ marginTop: 10 }}>
          <CustomSnackbarContent
            message={shopsState.error.message}
            open
            close={shopsState.error.closeMessage}
            color="danger"
          />
        </div>
      );
    }

    return null;
  };

  if (shopsState.error.exist && shopsState.error.hideContent) {
    return showErrorMessage();
  }

  const getTableData = () => {
    const tableData = [];

    shopsState.list.forEach((item, key) => {
      if (item.shop.status === 'inactive') {
        return;
      }

      // @todo: CustomTable complains when data is anything else rather a string, e.g Component (need investigation)
      // error: index.js:1446 Warning: Failed prop type: Invalid prop `tableData[0][0]` of type `object` supplied to `CustomTable`, expected `string`.
      // more info about the table: https://demos.creative-tim.com/material-dashboard-react/#/documentation/tables
      tableData.push([
        <FirebaseLogo
          key={key}
          defaultLogoUrl={logo}
          logoUrl={item.shop.logo}
        />,
        item.shop.name,
        item.shop.address,
        <NavLink key={key} to={`/edit-shop/${item.shop.id}`}>
          <CustomButton color="success" size="sm" key={key}>
            Edit
          </CustomButton>
        </NavLink>,
      ]);
    });

    return tableData;
  };

  const renderAddBtn = () => {
    if (shopsState.list.length === 0) {
      return (
        <div style={{ marginTop: 20 }}>
          <NavLink to="/add-shop">
            <CustomButton color="success">Add Shop</CustomButton>
          </NavLink>
        </div>
      );
    }
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <LoadingOverlay
          active={shopsState.processing}
          spinner={<HashLoader color="#4caf50" />}
        >
          {showErrorMessage()}
          <Card>
            <CardHeader color="success">
              <h5>Shop</h5>
              <p>View and manage your shop</p>
            </CardHeader>
            <CardBody>
              <CustomTable
                tableHeaderColor="success"
                tableHead={TABLE_HEADER}
                tableData={getTableData()}
              />
              {renderAddBtn()}
            </CardBody>
          </Card>
        </LoadingOverlay>
      </GridItem>
    </GridContainer>
  );
};

export default withStyles(styles)(Shops);
