import { FirebaseService } from '../../services/Firebase';
import { NotificationService } from '../../services/Notification';

import {
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAIL,
} from '../types/notification';

import { SUCCESS_GET_CODE } from '../../config';
import { ThrowException, getServerError } from '../../helpers';

export const getNotificationsAction = async (dispatch) => {
  dispatch({
    type: GET_NOTIFICATIONS_REQUEST,
    payload: null,
  });

  try {
    const token = await FirebaseService.getUserToken();

    const response = await NotificationService.getNotifications(token);

    if (response.status !== SUCCESS_GET_CODE) {
      ThrowException(
        response.status,
        'Something went wrong. Please refresh the page'
      );
    }

    dispatch({
      type: GET_NOTIFICATIONS_SUCCESS,
      payload: {
        notifications: response.data.data,
      },
    });
  } catch (e) {
    const error = getServerError(e.code, e.message);
    dispatch({
      type: GET_NOTIFICATIONS_FAIL,
      payload: {
        hideContent: error.hideContent,
        errorMessage: error.errorMessage,
        closeMessage: error.closeMessage,
      },
    });
  }
};
