import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import LoadingOverlay from 'react-loading-overlay-ts';
import HashLoader from 'react-spinners/HashLoader';

import { useUserContext } from '../../contexts/UserContext';

import CustomSnackbarContent from '../../components/CustomSnackbarContent';

import SignUpForm from '../../forms/SignUp';

import stampaLogo from '../../assets/img/logo.png';

import { createUserAction } from '../../actions/user/createUser';

import { sendWebTokenForPermissionGrantedAction } from '../../actions/webToken/sendWebTokenForPermissionGranted';

import styles from './styles';

import { CLEAR_ERROR } from '../../actions/types/user';

const SignUpPage = ({ classes }) => {
  const [userState, userDispatch] = useUserContext();

  useEffect(() => {
    return () => {
      userDispatch({
        type: CLEAR_ERROR,
        payload: null,
      });
    };
  }, []);

  const onCreateEmailAccountRequest = (formValues) => {
    if (formValues.reCaptcha !== 'triggered') {
      alert("Click I'm not a robot checkbox.");
    } else {
      // TODO: make this more dynamic coming from selected subscription. Hardcoded for now.
      formValues.subscription = 'basic';
      createUserAction(formValues, userDispatch);
    }
  };

  const showErrorMessage = () => {
    if (userState.error.exist) {
      return (
        <div style={{ marginTop: 20 }}>
          <CustomSnackbarContent
            message={userState.error.message}
            close
            open
            color="danger"
          />
        </div>
      );
    }

    return null;
  };

  if (userState.user.exist) {
    if (userState.user.verified) {
      //sendWebTokenForPermissionGrantedAction();

      return <Navigate to="/dashboard" replace={true} />;
    }

    if (!userState.user.verified) {
      return <Navigate to="/verify-email" replace={true} />;
    }
  }

  return (
    <main className={classes.main}>
      <LoadingOverlay
        active={userState.processing}
        spinner={<HashLoader color="#4caf50" />}
      >
        <CssBaseline />
        <Paper className={classes.paper}>
          <Avatar alt="Stampa" src={stampaLogo} className={classes.avatar} />

          <Typography component="h1" variant="h5" className={classes.h1}>
            Sign Up
          </Typography>
          {showErrorMessage()}
          <SignUpForm onSubmit={onCreateEmailAccountRequest} />
          <div className={classes.TCs}>
            Sign up is required because we upload all data to our cloud. By
            signing up, you agree to our{' '}
            <a
              href="https://www.stampa.app/website-terms-of-use.html"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.links}
            >
              Terms of Use
            </a>{' '}
            and{' '}
            <a
              href="https://www.stampa.app/website-privacy-policy.html"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.links}
            >
              Privacy Policy
            </a>
          </div>
          <div style={{ marginTop: 15 }}>
            Do you have already an account? Click{' '}
            <Link to="/login" className={classes.links}>
              here
            </Link>
            .
          </div>
        </Paper>
      </LoadingOverlay>
    </main>
  );
};

export default withStyles(styles)(SignUpPage);
