import React, { createContext, useContext, useReducer } from 'react';
import shopsReducer, { initialState } from '../reducers/shops';

const ShopsContext = createContext([initialState, () => ({})]);

export const ShopsProvider = ({ children }) => {
  const value = useReducer(shopsReducer, initialState);
  return (
    <ShopsContext.Provider value={value}>{children}</ShopsContext.Provider>
  );
};

export const useShopsContext = () => useContext(ShopsContext);
