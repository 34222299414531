import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import withStyles from '@material-ui/core/styles/withStyles';

import GridItem from '../../../components/Grid/Item';
import GridContainer from '../../../components/Grid/Container';
import Card from '../../../components/Card';
import CardBody from '../../../components/Card/Body';
import CardFooter from '../../../components/Card/Footer';
import FileUploader from '../../../components/FileUploader';
import CustomField from '../../../components/CustomField';
import CustomButton from '../../../components/CustomButton';

import styles from './styles';

const EditShopForm = ({ onSubmit, onCancel, initialValues, logo, classes }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const [fileExist, setFileExist] = useState(false);
  const [logoRemoved, setLogoRemoved] = useState(false);

  useEffect(() => {
    if (fileExist === false) {
      setValue('file_upload', '');
    }
  }, [fileExist]);

  useEffect(() => {
    setValue('logo_removed', logoRemoved);
  }, [logoRemoved]);

  const onFileChange = (e, field) => {
    const file = e.target.files[0];

    if (Math.round(file.size / 1024) > 1000) {
      field.onChange('');
      alert(
        'The file size is more than 1MB. Please try to upload another one.'
      );
      setFileExist(false);
    } else {
      field.onChange(e.target.files[0]);
      setFileExist(true);
    }
  };

  const onRemoveFile = () => {
    setFileExist(false);
  };

  const renderLogo = () => {
    if (initialValues.logo === '' || logoRemoved) {
      return (
        <div style={{ display: 'flex' }}>
          <FileUploader
            onFileChange={onFileChange}
            accept="image/*"
            name="file_upload"
            fileExist={fileExist}
            onRemoveFile={onRemoveFile}
            control={control}
          />
        </div>
      );
    }

    return (
      <div style={{ display: 'flex', marginRight: 20, alignItems: 'center' }}>
        <div style={{ marginRight: 20 }}>{logo}</div>
        <div>
          <CustomButton
            color="danger"
            size="sm"
            onClick={() => setLogoRemoved(true)}
          >
            Delete logo
          </CustomButton>
        </div>
      </div>
    );
  };

  const getHiddenInputs = () => {
    return (
      <React.Fragment>
        <Controller
          name="logo_removed"
          control={control}
          defaultValue={logoRemoved}
          render={({ field }) => (
            <input
              type="hidden"
              onChange={(e) => field.onChange(e.target.files[0])}
            />
          )}
        />
        <Controller
          name="logo"
          control={control}
          defaultValue={initialValues.logo}
          render={({ field }) => (
            <input
              type="hidden"
              onChange={(e) => field.onChange(e.target.files[0])}
            />
          )}
        />
      </React.Fragment>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <CustomField
                name="name"
                control={control}
                errors={errors}
                rules={{
                  required: 'Business Name is required',
                }}
                id="name"
                label="Business Name"
                type="text"
                defaultValue={initialValues.name}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <CustomField
                name="description"
                control={control}
                errors={errors}
                rules={{
                  required: 'Business Description is required',
                }}
                id="description"
                label="Business Description"
                type="text"
                defaultValue={initialValues.description}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <CustomField
                name="address"
                control={control}
                errors={errors}
                rules={{
                  required: 'Business Address is required',
                }}
                id="address"
                label="Business Address"
                type="text"
                defaultValue={initialValues.address}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomField
                name="postcode"
                control={control}
                errors={errors}
                rules={{
                  required: 'Business Postcode is required',
                }}
                id="postcode"
                label="Business Postcode"
                type="text"
                defaultValue={initialValues.postcode}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div
                style={{
                  color: '#008000',
                  fontWeight: 400,
                  fontSize: 16,
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                Business Logo:
              </div>
              <div>{renderLogo()}</div>
              <div
                style={{
                  marginTop: 5,
                  fontSize: 12,
                  color: 'orange',
                  fontWeight: 'bold',
                }}
              >
                *Maximum file size could be up to 1MB. We recommend logos with
                dimensions between 512px to 1024px.
              </div>
              {getHiddenInputs()}
            </GridItem>
          </GridContainer>
        </CardBody>
        <CardFooter>
          <div style={{ flex: 1 }}>
            <CustomButton
              color="success"
              type="submit"
              style={{ marginRight: 20 }}
            >
              Update
            </CustomButton>
            <CustomButton color="danger" onClick={() => onCancel()}>
              Cancel
            </CustomButton>
          </div>
        </CardFooter>
      </Card>
    </form>
  );
};

export default withStyles(styles)(EditShopForm);
