import React, { useState, useEffect } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import LoadingOverlay from 'react-loading-overlay-ts';
import HashLoader from 'react-spinners/HashLoader';

import CustomButton from '../../../../components/CustomButton';
import CardBody from '../../../../components/Card/Body';
import CardFooter from '../../../../components/Card/Footer';

import CustomSnackbarContent from '../../../../components/CustomSnackbarContent';

import EditStampOfferForm from '../../../../forms/Shop/EditStampOffer';

import {
  getStampOfferAction,
  initialState as stampOfferInitialState,
} from '../../../../actions/stampOffer/getStampOffer';

import {
  updateStampOfferAction,
  initialState as updateStampOfferInitialState,
} from '../../../../actions/stampOffer/updateStampOffer';

const StampOffer = ({ shopId }) => {
  const [editMode, setEditMode] = useState(false);
  const [stampOfferStatus, setStampOfferStatus] = useState(
    stampOfferInitialState
  );
  const [updateStampOfferStatus, setUpdateStampOfferStatus] = useState(
    updateStampOfferInitialState
  );

  useEffect(() => {
    getStampOfferAction(shopId, setStampOfferStatus);
  }, []);

  useEffect(() => {
    if (updateStampOfferStatus.updated) {
      setEditMode(false);
      getStampOfferAction(shopId, setStampOfferStatus);
    }
  }, [updateStampOfferStatus]);

  const onUpdateStampOfferRequest = (formValues) => {
    if (
      parseInt(stampOfferStatus.data.num_stamps) !==
      parseInt(formValues.num_stamps)
    ) {
      if (
        window.confirm(
          'You have changed the number of required stamps that might affect your customers. Do you want to proceed?'
        )
      ) {
        updateStampOffer(formValues);
      }
    } else {
      updateStampOffer(formValues);
    }
  };

  const updateStampOffer = (formValues) => {
    updateStampOfferAction(
      shopId,
      stampOfferStatus.data.id,
      formValues,
      setUpdateStampOfferStatus
    );
  };

  const onCancelEditStampOffer = () => {
    setEditMode(false);
  };

  const renderStampOfferForm = () => {
    return (
      <LoadingOverlay
        active={updateStampOfferStatus.processing}
        spinner={<HashLoader color="#4caf50" />}
      >
        {showErrorMessages(updateStampOfferStatus)}
        <EditStampOfferForm
          onSubmit={onUpdateStampOfferRequest}
          onCancel={onCancelEditStampOffer}
          initialValues={stampOfferStatus.data}
        />
      </LoadingOverlay>
    );
  };

  const showErrorMessages = (errorObj) => {
    if (errorObj.error.exist && errorObj.error.message !== undefined) {
      const errorMessages = errorObj.error.message;

      for (var k in errorMessages) {
        return errorMessages[k].map((message, key) => {
          return (
            <div style={{ marginTop: 10 }} key={key}>
              <CustomSnackbarContent
                message={message}
                close
                open
                color="danger"
              />
            </div>
          );
        });
      }
    }

    return null;
  };

  const renderStampOfferView = () => {
    if (stampOfferStatus.processing) {
      return (
        <LoadingOverlay active={true} spinner={<HashLoader color="#4caf50" />}>
          <div style={{ width: '100%', height: 150 }}>&nbsp;</div>
        </LoadingOverlay>
      );
    }

    if (Object.keys(stampOfferStatus.data).length === 0) {
      return null;
    }

    if (stampOfferStatus.error.exist) {
      return <CardBody>{showErrorMessages(stampOfferStatus)}</CardBody>;
    }

    return (
      <React.Fragment>
        <CardBody>
          <List dense={false}>
            <ListItem>
              <ListItemText
                primary="Reward description:"
                secondary={stampOfferStatus.data.description}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={`Number of stamps: ${stampOfferStatus.data.num_stamps}`}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Terms and Conditions:"
                secondary={
                  stampOfferStatus.data.policy !== ''
                    ? stampOfferStatus.data.policy
                    : 'Terms and Conditions have not been submitted.'
                }
              />
            </ListItem>
          </List>
        </CardBody>
        <CardFooter>
          <CustomButton color="success" onClick={() => setEditMode(true)}>
            Edit Stamps
          </CustomButton>
        </CardFooter>
      </React.Fragment>
    );
  };

  if (editMode) {
    return renderStampOfferForm();
  }

  return renderStampOfferView();
};

export default StampOffer;
