import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import DashboardShop from '../../components/Dashboard/Shop';
import DashboardPermanentStamp from '../../components/Dashboard/PermanentStamp';
import GridContainer from '../../components/Grid/Container';
import GridItem from '../../components/Grid/Item';
import CustomSnackbarContent from '../../components/CustomSnackbarContent';
import { NotificationPermissionMessage } from '../../components/NotificationPermissionMessage';
import { FirebaseService } from '../../services/Firebase';

import { isNotificationSupported } from '../../helpers';

import styles from './styles';

const Dashboard = () => {
  const renderUnsupportedBrowserMessage = () => {
    if (
      FirebaseService.isMessagingSupported() === false &&
      isNotificationSupported()
    ) {
      return (
        <CustomSnackbarContent
          message="This browser does not support notifications and some functions might not fully work. You could use a fully compatible browser such as Chrome or Firefox."
          open
        />
      );
    }

    return null;
  };

  const renderDashboard = () => {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <DashboardShop />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <DashboardPermanentStamp />
        </GridItem>
      </GridContainer>
    );
  };

  return (
    <div>
      {renderUnsupportedBrowserMessage()}
      <NotificationPermissionMessage canShowMessage={true} />
      {renderDashboard()}
    </div>
  );
};

export default withStyles(styles)(Dashboard);
