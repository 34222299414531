/* eslint-disable react/no-string-refs */
import React, { useEffect, useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Sidebar from '../../components/Sidebar';
import { NotificationAlert } from '../../components/NotificationAlert';
import { SubscriptionMessage } from '../../components/SubscriptionMessage';

import dashboardRoutes from '../../routes/dashboard.js';

import image from '../../assets/img/sidebar-2.jpg';
import logo from '../../assets/img/logo.png';

import styles from './styles';

const DashboardLayout = ({ ...props }) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const menuLinkIsClicked = () => {
    if (mobileOpen) {
      setMobileOpen(false);
    }
  };

  const { classes, ...rest } = props;

  return (
    <div className={classes.wrapper}>
      <NotificationAlert />
      <Sidebar
        routes={dashboardRoutes}
        logoText="Stampa"
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        menuLinkIsClicked={menuLinkIsClicked}
        open={mobileOpen}
        color="green"
        {...rest}
      />
      <div className={classes.mainPanel}>
        <Header
          routes={dashboardRoutes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        <SubscriptionMessage />
        <div className={classes.content}>
          <div className={classes.container}>{props.children}</div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default withStyles(styles)(DashboardLayout);
