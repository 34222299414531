import React from 'react';

const Loading = () => {
  return (
    <div style={{ textAlign: 'center', fontSize: '22px', paddingTop: '100px' }}>
      Loading...
    </div>
  );
};

export default Loading;
