import React, { useState, useEffect } from 'react';
import moment from 'moment';
import withStyles from '@material-ui/core/styles/withStyles';
import LoadingOverlay from 'react-loading-overlay-ts';
import HashLoader from 'react-spinners/HashLoader';

import GridContainer from '../../components/Grid/Container';
import GridItem from '../../components/Grid/Item';
import Card from '../../components/Card';
import CardHeader from '../../components/Card/Header';
import CardBody from '../../components/Card/Body';
import CustomTable from '../../components/CustomTable';
import CustomButton from '../../components/CustomButton';
import CustomDialog from '../../components/CustomDialog';
import CustomSnackbarContent from '../../components/CustomSnackbarContent';
import { useNotificationsContext } from '../../contexts/NotificationsContext';
import { getNotificationsAction } from '../../actions/notification/getNotifications';
import {
  updateRewardStatusAction,
  initialState as updateRewardStatusInitialState,
} from '../../actions/reward/updateRewardStatus';

import styles from './styles';

const TABLE_HEADER = ['Action', 'Name', 'Email', 'Reward', 'Date'];
const CONFIRM_ACCEPT_ACTION = 'accept';
const CONFIRM_DECLINE_ACTION = 'decline';
const INITIAL_CONFIRM_DETAILS = {
  visible: false,
  action: null,
  details: {},
};
const AVAILABLE_STATUS = {
  accept: 'claimed',
  decline: 'declined',
};

const RedeemRequests = ({ classes }) => {
  const [confirmDetails, setConfirmDetails] = useState(INITIAL_CONFIRM_DETAILS);
  const [notificationsState, notificationsDispatch] = useNotificationsContext();
  const [rewardStatus, setRewardStatus] = useState(
    updateRewardStatusInitialState
  );

  useEffect(() => {
    getNotificationsAction(notificationsDispatch);
  }, []);

  useEffect(() => {
    if (rewardStatus.updated) {
      setConfirmDetails({
        ...INITIAL_CONFIRM_DETAILS,
        action: confirmDetails.action,
      });
      getNotificationsAction(notificationsDispatch);
    }
    if (rewardStatus.error.exist) {
      setConfirmDetails(INITIAL_CONFIRM_DETAILS);
    }
  }, [rewardStatus]);

  const handleClose = () => {
    setConfirmDetails(INITIAL_CONFIRM_DETAILS);
  };

  const renderButtons = (
    acceptText,
    declineText,
    acceptOnClick,
    declineOnClick
  ) => {
    return (
      <div>
        <CustomButton
          color="success"
          size="sm"
          style={{ marginRight: '10px' }}
          onClick={() => acceptOnClick()}
        >
          {acceptText}
        </CustomButton>
        <CustomButton color="danger" size="sm" onClick={() => declineOnClick()}>
          {declineText}
        </CustomButton>
      </div>
    );
  };

  const getTableData = () => {
    const tableData = notificationsState.notifications.map((notification) => {
      return [
        renderButtons(
          'Accept',
          'Decline',
          () =>
            setConfirmDetails({
              visible: true,
              action: CONFIRM_ACCEPT_ACTION,
              details: notification,
            }),
          () =>
            setConfirmDetails({
              visible: true,
              action: CONFIRM_DECLINE_ACTION,
              details: notification,
            })
        ),
        notification.name,
        notification.email,
        notification.offer_description,
        moment.unix(notification.created_at.date).format('LLL'),
      ];
    });

    return tableData;
  };

  const dialogText = () => {
    return (
      <span style={{ display: 'block' }}>
        <span style={{ display: 'block' }}>
          Are you sure you want to {confirmDetails.action} the redeem request
          from {confirmDetails.details.name}?
        </span>
        <span style={{ fontSize: '12px', marginTop: '20px', display: 'block' }}>
          <span style={{ display: 'block' }}>
            <b>Reward:</b> {confirmDetails.details.offer_description}
          </span>
          <span style={{ display: 'block' }}>
            <b>Request Date:</b>{' '}
            {moment.unix(confirmDetails.details.created_at.date).format('LLL')}
          </span>
        </span>
      </span>
    );
  };

  const renderDialog = () => {
    if (confirmDetails.visible) {
      return (
        <CustomDialog
          open={true}
          onClose={handleClose}
          title="Please confirm your option"
          text={dialogText()}
          buttons={[
            {
              text: 'No',
              color: 'danger',
              onClick: handleClose,
            },
            {
              text: 'Yes',
              color: 'success',
              onClick: () => {
                updateRewardStatusAction(
                  setRewardStatus,
                  confirmDetails.details.id,
                  AVAILABLE_STATUS[confirmDetails.action]
                );
              },
            },
          ]}
        />
      );
    }
  };

  const renderCardBody = () => {
    return (
      <CustomTable
        tableHeaderColor="success"
        tableHead={TABLE_HEADER}
        tableData={getTableData()}
      />
    );
  };

  const showErrorMessage = () => {
    if (
      notificationsState.error.exist &&
      notificationsState.error.message !== undefined
    ) {
      return (
        <div style={{ marginTop: 10 }}>
          <CustomSnackbarContent
            message={notificationsState.error.message}
            close
            open
            color="danger"
          />
        </div>
      );
    }

    if (rewardStatus.error.exist && rewardStatus.error.message !== '') {
      return (
        <div style={{ marginTop: 10 }}>
          <CustomSnackbarContent
            message={rewardStatus.error.message}
            close
            open
            color="danger"
          />
        </div>
      );
    }

    return null;
  };

  const showSuccessStatusMessage = () => {
    if (rewardStatus.updated) {
      return (
        <div style={{ marginTop: 10 }}>
          <CustomSnackbarContent
            message={`Your action ${confirmDetails.action} has been competed.`}
            close
            open
            color="success"
          />
        </div>
      );
    }

    return null;
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <LoadingOverlay
          active={notificationsState.processing || rewardStatus.processing}
          spinner={<HashLoader color="#4caf50" />}
        >
          {showErrorMessage()}
          {showSuccessStatusMessage()}
          {renderDialog()}
          <Card>
            <CardHeader color="success">
              <h4 className={classes.cardTitleWhite}>Redeem Requests</h4>
            </CardHeader>
            <CardBody>{renderCardBody()}</CardBody>
          </Card>
        </LoadingOverlay>
      </GridItem>
    </GridContainer>
  );
};

export default withStyles(styles)(RedeemRequests);
