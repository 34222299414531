import axios from 'axios';

const generateStampEndPoint = process.env.REACT_APP_API_URI + 'stamps';

export const StampService = {
  /**
   * Get user details.
   *
   * @param {string} token
   *
   * @returns {Array}
   */
  async getStamp(token, shopId) {
    return axios({
      method: 'post',
      url: generateStampEndPoint,
      timeout: process.env.REACT_APP_API_REQUEST_TIMEOUT,
      headers: {
        Authorization: token,
      },
      data: {
        shop_id: shopId,
      },
    })
      .then((response) => response)
      .catch((error) => error.response);
  },

  async generatePermanentStamp(token, shopId) {
    return axios({
      method: 'put',
      url: process.env.REACT_APP_API_URI + `shops/${shopId}/generate-stamp`,
      timeout: process.env.REACT_APP_API_REQUEST_TIMEOUT,
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response)
      .catch((error) => error.response);
  },

  async removePermanentStamp(token, shopId) {
    return axios({
      method: 'put',
      url: process.env.REACT_APP_API_URI + `shops/${shopId}/remove-stamp`,
      timeout: process.env.REACT_APP_API_REQUEST_TIMEOUT,
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response)
      .catch((error) => error.response);
  },
};
