import React from 'react';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import GridItem from '../../components/Grid/Item';
import GridContainer from '../../components/Grid/Container';
import Card from '../../components/Card';
import CardHeader from '../../components/Card/Header';
import CardBody from '../../components/Card/Body';

import styles from './styles';

const Policy = ({ classes }) => {
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
              <h4>Policy</h4>
            </CardHeader>
            <CardBody>
              <p style={{ fontSize: 15 }}>
                Please find the links below for our{' '}
                <span className={classes.text}>Privacy Policy</span> and{' '}
                <span className={classes.text}>Terms of Use</span>.
              </p>
              <p>
                <a
                  href="https://www.stampa.app/website-privacy-policy.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.link}
                >
                  Privacy Policy
                </a>
              </p>
              <p>
                <a
                  href="https://www.stampa.app/website-terms-of-use.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.link}
                >
                  Terms of Use
                </a>
              </p>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default withStyles(styles)(Policy);
