import { FirebaseService } from '../../services/Firebase';
import { LocalStorageService } from '../../services/LocalStorage';

import { LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGOUT_FAIL } from '../types/user';

export const logoutUserAction = async (dispatch) => {
  dispatch({
    type: LOGOUT_REQUEST,
    payload: null,
  });

  // logout user from firebase - async
  FirebaseService.logoutUser();

  try {
    // delete user from database
    LocalStorageService.deleteItem('user');

    dispatch({
      type: LOGOUT_SUCCESS,
      payload: null,
    });
  } catch (e) {
    dispatch({
      type: LOGOUT_FAIL,
      payload: {
        errorMessage: e.errorMessage,
      },
    });
  }
};
