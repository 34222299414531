import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import withStyles from '@material-ui/core/styles/withStyles';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Icon from '@material-ui/core/Icon';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import styles from './styles';

const Sidebar = ({ ...props }) => {
  const [extendedMenu, setExtendedMenu] = useState([]);

  useEffect(() => {
    const { routes } = props;

    routes.forEach((obj) => {
      if (obj.children) {
        obj.children.forEach((child) => {
          if (activeRoute(child.path)) {
            toggleMenu(obj.id);
          }
        });
      }
    });
  }, []);

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? true : false;
  };

  const getBrand = () => {
    const { classes, logo, logoText } = props;

    return (
      <div className={classes.logo}>
        <a href="/" className={classes.logoLink}>
          <span className={classes.logoImage}>
            <img src={logo} alt="logo" className={classes.img} />
          </span>
          <span className={classes.logoText}>{logoText}</span>
        </a>
      </div>
    );
  };

  const getMonoLink = (
    prop,
    classes,
    key,
    listItemClasses,
    whiteFontClasses
  ) => {
    return (
      <NavLink
        to={prop.path}
        className={classes.item}
        activeClassName="active"
        key={key}
        onClick={props.menuLinkIsClicked}
      >
        <ListItem button className={classes.itemLink + listItemClasses}>
          <ListItemIcon className={classes.itemIcon + whiteFontClasses}>
            {typeof prop.icon === 'string' ? (
              <Icon>{prop.icon}</Icon>
            ) : (
              <prop.icon />
            )}
          </ListItemIcon>
          <ListItemText
            primary={prop.sidebarName}
            className={classes.itemText + whiteFontClasses}
            disableTypography={true}
          />
        </ListItem>
      </NavLink>
    );
  };

  const getArrowIcon = (id) => {
    if (isExpended(id)) {
      return <ArrowDropDownIcon />;
    } else {
      return <ArrowDropUpIcon />;
    }
  };

  const isExpended = (id) => {
    return extendedMenu.includes(id);
  };

  const toggleMenu = (id) => {
    if (isExpended(id)) {
      setExtendedMenu(extendedMenu.filter((item) => item !== id));
    } else {
      // add
      setExtendedMenu([...extendedMenu, id]);
    }
  };

  const getMultiLinks = (key, classes, prop, color) => {
    const subLinks = prop.children.map((item, key) => {
      const listItemClasses = classNames({
        [' ' + classes[color]]: activeRoute(item.path),
      });

      const whiteFontClasses = classNames({
        [' ' + classes.whiteFont]: activeRoute(item.path),
      });

      return (
        <NavLink
          to={item.path}
          className={classes.item}
          activeClassName="active"
          key={key}
          onClick={props.menuLinkIsClicked}
        >
          <ListItem button className={classes.itemLink + listItemClasses}>
            <ListItemIcon className={classes.itemSmallIcon + whiteFontClasses}>
              {typeof item.icon === 'string' ? (
                <Icon>{item.icon}</Icon>
              ) : (
                <item.icon className={classes.smallIcon} />
              )}
            </ListItemIcon>
            <ListItemText
              primary={item.sidebarName}
              className={classes.itemText + whiteFontClasses}
              disableTypography={true}
            />
          </ListItem>
        </NavLink>
      );
    });

    return (
      <React.Fragment key={key}>
        <ListItem
          button
          className={classes.itemLink}
          onClick={() => toggleMenu(prop.id)}
        >
          <ListItemIcon className={classes.itemIcon}>
            {typeof prop.icon === 'string' ? (
              <Icon>{prop.icon}</Icon>
            ) : (
              <prop.icon />
            )}
          </ListItemIcon>
          <ListItemText
            primary={prop.sidebarName}
            className={classes.itemText}
            disableTypography={true}
          />
          <ListItemIcon className={classes.itemArrowIcon}>
            {getArrowIcon(prop.id)}
          </ListItemIcon>
        </ListItem>
        <Collapse in={isExpended(prop.id)}>
          <List style={{ padding: 0 }}>{subLinks}</List>
        </Collapse>
      </React.Fragment>
    );
  };

  const getLinks = () => {
    const { classes, color, routes } = props;

    const output = routes.map((prop, key) => {
      if (prop.redirect || prop.hideSidebarName) {
        return null;
      }

      const listItemClasses = classNames({
        [' ' + classes[color]]: activeRoute(prop.path),
      });

      const whiteFontClasses = classNames({
        [' ' + classes.whiteFont]: activeRoute(prop.path),
      });

      if (prop.children) {
        return getMultiLinks(key, classes, prop, color);
      } else {
        return getMonoLink(
          prop,
          classes,
          key,
          listItemClasses,
          whiteFontClasses
        );
      }
    });

    return output;
  };

  const getBackgroundImage = () => {
    const { classes, image } = props;

    if (image !== undefined) {
      return (
        <div
          className={classes.background}
          style={{ backgroundImage: 'url(' + image + ')' }}
        />
      );
    }

    return null;
  };

  const { classes } = props;

  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="right"
          open={props.open}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {getBrand()}
          <div className={classes.sidebarWrapper}>
            <List className={classes.list}>{getLinks()}</List>
          </div>
          {getBackgroundImage()}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor="left"
          variant="permanent"
          open
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {getBrand()}
          <div className={classes.sidebarWrapper}>
            <List className={classes.list}>{getLinks()}</List>
          </div>
          {getBackgroundImage()}
        </Drawer>
      </Hidden>
    </div>
  );
};

export default withStyles(styles)(Sidebar);
