import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Controller } from 'react-hook-form';
import CustomButton from '../../components/CustomButton';

import styles from './styles';

const FileUploader = ({
  onFileChange,
  name,
  accept,
  fileExist,
  onRemoveFile,
  control,
}) => {
  const renderRemoveFile = () => {
    if (fileExist) {
      return (
        <CustomButton color="danger" size="sm" onClick={() => onRemoveFile()}>
          Remove file
        </CustomButton>
      );
    }

    return null;
  };

  return (
    <div>
      <Controller
        name={name}
        control={control}
        defaultValue={null}
        render={({ field }) => (
          <input
            type="file"
            accept={accept}
            onChange={(e) => onFileChange(e, field)}
          />
        )}
      />
      {renderRemoveFile()}
    </div>
  );
};

export default withStyles(styles)(FileUploader);
