import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import { onMessage } from 'firebase/messaging';

import Snackbar from '../Snackbar';
import { FirebaseService } from '../../services/Firebase';
import { useNotificationsContext } from '../../contexts/NotificationsContext';
import { getNotificationsAction } from '../../actions/notification/getNotifications';

const INITIAL_STATE = {
  show: false,
  message: null,
  click_action: null,
};

export const NotificationAlert = () => {
  const navigate = useNavigate();
  const [showNotification, setShowNotification] = useState(INITIAL_STATE);
  const [, notificationsDispatch] = useNotificationsContext();

  useEffect(() => {
    if (FirebaseService.isMessagingSupported()) {
      const messaging = FirebaseService.getMessaging();
      onMessage(messaging, (payload) => {
        setShowNotification({
          show: true,
          message: payload.data.body,
          click_action: payload.data.click_action,
        });
      });
    }
  }, []);

  const goToPage = (path) => {
    getNotificationsAction(notificationsDispatch);
    navigate(path);
    setShowNotification(INITIAL_STATE);
  };

  if (showNotification.show) {
    return (
      <Snackbar
        place="tr"
        color="success"
        icon={NotificationsActiveIcon}
        message={showNotification.message}
        open={true}
        closeNotification={() => setShowNotification(INITIAL_STATE)}
        close
        clickNotification={() => goToPage(showNotification.click_action)}
      />
    );
  }
  return null;
};
