import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

import styles from './styles';

const Footer = ({ ...props }) => {
  const { classes } = props;

  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <p className={classes.right}>
          <span>&copy; {1900 + new Date().getYear()} Stampa</span>
        </p>
      </div>
    </footer>
  );
};

export default withStyles(styles)(Footer);
