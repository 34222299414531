import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import QRCode from 'qrcode.react';
import LoadingOverlay from 'react-loading-overlay-ts';
import HashLoader from 'react-spinners/HashLoader';

import GridItem from '../../components/Grid/Item';
import GridContainer from '../../components/Grid/Container';
import Card from '../../components/Card';
import CardHeader from '../../components/Card/Header';
import CardBody from '../../components/Card/Body';
import CardFooter from '../../components/Card/Footer';

import CustomButton from '../../components/CustomButton';
import CustomSnackbarContent from '../../components/CustomSnackbarContent';

import { useShopsContext } from '../../contexts/ShopsContext';

import { getShopsAction } from '../../actions/shop/getShops';

import {
  getStampAction,
  initialState as stampInitialState,
} from '../../actions/stamp/getStamp';

const GenerateStamp = () => {
  const [shopsState, shopsDispatch] = useShopsContext();
  const [stampStatus, setStampStatus] = useState(stampInitialState);

  useEffect(() => {
    getShopsAction(shopsDispatch);

    return setStampStatus(stampInitialState);
  }, []);

  const generateStampRequest = (shopId) => {
    if (typeof shopId !== 'number') {
      alert(
        'There might be an issue going on. Please refresh the page and try again.'
      );
    } else {
      getStampAction(shopId, setStampStatus);
    }
  };

  const renderQrCode = () => {
    if (stampStatus.hashCode !== '' || stampStatus.processing) {
      return (
        <div style={{ textAlign: 'center', alignItems: 'center' }}>
          <QRCode
            id="qr-code"
            value={stampStatus.hashCode}
            size={290}
            level={'H'}
            includeMargin={true}
          />
        </div>
      );
    }

    return (
      <p style={{ textAlign: 'center' }}>
        Press the button to generate a stamp.
      </p>
    );
  };

  const renderQrCodeButton = () => {
    if (shopsState.list.length === 0 && shopsState.processing === false) {
      return (
        <React.Fragment>
          <CardBody>
            <p style={{ paddingTop: 10, fontSize: 16 }}>
              {
                "It seems you haven't added a shop yet. Press the button below to add a shop."
              }
            </p>
          </CardBody>
          <CardFooter stats>
            <NavLink to="/add-shop" style={{ margin: 'auto' }}>
              <CustomButton color="success">Add Shop</CustomButton>
            </NavLink>
          </CardFooter>
        </React.Fragment>
      );
    }

    if (shopsState.list.length !== 0) {
      const shopId = shopsState.list[0].shop.id;
      return (
        <CardBody>
          {renderQrCode()}
          <div style={{ textAlign: 'center', alignItems: 'center' }}>
            <CustomButton
              color="success"
              onClick={() => {
                generateStampRequest(shopId);
              }}
            >
              Generate Stamp
            </CustomButton>
          </div>
        </CardBody>
      );
    }

    return false;
  };

  const showErrorMessages = () => {
    if (stampStatus.error.exist && stampStatus.error.message !== '') {
      return (
        <div style={{ marginTop: 10 }}>
          <CustomSnackbarContent
            message={stampStatus.error.message}
            close
            open
            color="danger"
          />
        </div>
      );
    }

    return null;
  };

  const showErrorMessage = () => {
    if (shopsState.error.exist) {
      return (
        <div style={{ marginTop: 10 }}>
          <CustomSnackbarContent
            message={shopsState.error.message}
            open
            close={shopsState.error.closeMessage}
            color="danger"
          />
        </div>
      );
    }

    return null;
  };

  if (shopsState.error.exist && shopsState.error.hideContent) {
    return showErrorMessage();
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={6}>
        <LoadingOverlay
          active={stampStatus.processing}
          spinner={<HashLoader color="#4caf50" />}
        >
          {showErrorMessages()}
          <Card>
            <CardHeader color="success">
              <h4>Generate Stamp</h4>
            </CardHeader>
            {renderQrCodeButton()}
          </Card>
        </LoadingOverlay>
      </GridItem>
    </GridContainer>
  );
};

export default GenerateStamp;
