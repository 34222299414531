import React, { useEffect, useState } from 'react';
import { FirebaseService } from './services/Firebase';
import { LocalStorageService } from './services/LocalStorage';
import { UserProvider } from './contexts/UserContext';
import { ShopsProvider } from './contexts/ShopsContext';
import { NotificationsProvider } from './contexts/NotificationsContext';
import { sendWebTokenForPermissionGrantedAction } from './actions/webToken/sendWebTokenForPermissionGranted';
import MainRouter from './routers/Main';
import Loading from './components/Loading';

const App = () => {
  const [ready, setReady] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    (async function init() {
      FirebaseService.init();

      if (FirebaseService.isMessagingSupported()) {
        sendWebTokenForPermissionGrantedAction();
      }

      const user = await LocalStorageService.selectItem('user');

      setUser(user);
      setReady(true);
    })();
  }, []);

  if (ready === false) {
    return <Loading />;
  }

  return (
    <UserProvider user={user}>
      <ShopsProvider>
        <NotificationsProvider>
          <MainRouter />
        </NotificationsProvider>
      </ShopsProvider>
    </UserProvider>
  );
};

export default App;
