import Dashboard from '@material-ui/icons/Dashboard';
import ImportContacts from '@material-ui/icons/ImportContacts';
//import RedeemIcon from '@material-ui/icons/Redeem';
import InputIcon from '@material-ui/icons/Input';
//import ContactMailIcon from '@material-ui/icons/ContactMail';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import Store from '@material-ui/icons/Store';
import PowerOff from '@material-ui/icons/PowerSettingsNew';
import CropFreeIcon from '@material-ui/icons/CropFree';
import Security from '@material-ui/icons/Security';
import Receipt from '@material-ui/icons/Receipt';
import Person from '@material-ui/icons/Person';

import DashboardPage from '../pages/Dashboard';
import GenerateStamp from '../pages/GenerateStamp';
import ShopsPage from '../pages/Shops';
import HowItWorksPage from '../pages/HowItWorks';
import PolicyPage from '../pages/Policy';
import SupportPage from '../pages/Support';
import ShopDetails from '../pages/Shops/Details';
import AddShop from '../pages/Shops/Add';
import RedeemRequestsPage from '../pages/RedeemRequests';
import LogoutPage from '../pages/Logout';
import FailedSubscription from '../pages/FailedSubscription';
import SuccessSubscription from '../pages/SuccessSubscription';
import Billing from '../pages/Billing';

const dashboardRoutes = [
  {
    path: '/dashboard',
    sidebarName: 'Dashboard',
    navbarName: 'Dashboard',
    icon: Dashboard,
    component: DashboardPage,
  },
  {
    path: '/shop',
    sidebarName: 'Shop',
    navbarName: 'Shop',
    icon: Store,
    component: ShopsPage,
  },
  {
    path: '/generate-stamp',
    sidebarName: 'Generate Stamp',
    navbarName: 'Generate Stamp',
    icon: CropFreeIcon,
    component: GenerateStamp,
  },
  {
    path: '/add-shop',
    sidebarName: 'Add Shop',
    navbarName: 'Add Shop',
    icon: Store,
    component: AddShop,
    hideSidebarName: true,
  },
  {
    path: '/edit-shop/:id',
    sidebarName: 'Shops Details',
    navbarName: 'Shop Details',
    icon: Store,
    component: ShopDetails,
    hideSidebarName: true,
  },
  {
    path: '/redeem-requests',
    sidebarName: 'Redeem Requests',
    navbarName: 'Redeem Requests',
    icon: InputIcon,
    component: RedeemRequestsPage,
  },
  {
    id: 'account',
    sidebarName: 'Account',
    navbarName: 'Account',
    icon: Person,
    children: [
      {
        path: '/how-it-works',
        sidebarName: 'How It Works',
        navbarName: 'How It Works',
        icon: ImportContacts,
        component: HowItWorksPage,
      },
      {
        path: '/policy',
        sidebarName: 'Policy',
        navbarName: 'Policy',
        icon: Security,
        component: PolicyPage,
      },
      {
        path: '/support',
        sidebarName: 'Support',
        navbarName: 'Support',
        icon: ContactSupportIcon,
        component: SupportPage,
      },
      {
        path: '/billing',
        sidebarName: 'Billing',
        navbarName: 'Billing',
        icon: Receipt,
        component: Billing,
      },
      {
        path: '/logout',
        sidebarName: 'Logout',
        navbarName: 'Logout',
        icon: PowerOff,
        component: LogoutPage,
      },
    ],
  },
  {
    path: '/failed-subscription',
    navbarName: 'Failed Subscription',
    hideSidebarName: true,
    component: FailedSubscription,
  },
  {
    path: '/success-subscription',
    navbarName: 'Success Subscription',
    hideSidebarName: true,
    component: SuccessSubscription,
  },
];

export default dashboardRoutes;
