import { getToken } from 'firebase/messaging';
import { FirebaseService } from '../../services/Firebase';
import {
  isNotificationSupported,
  getNotificationPermission,
} from '../../helpers';
import { sendWebTokenAction } from './sendWebToken';

export const sendWebTokenForPermissionGrantedAction = () => {
  if (
    isNotificationSupported() &&
    getNotificationPermission() === 'granted' &&
    FirebaseService.isMessagingSupported()
  ) {
    const messaging = FirebaseService.getMessaging();

    getToken(messaging)
      .then((currentToken) => {
        if (currentToken) {
          sendWebTokenAction(currentToken);
        } else {
          // TODO: log this error
          // console.log('Failed to get a token');
        }
      })
      .catch((errorToken) => {
        // TODO: log this error
        // console.log(errorToken.code, errorToken.message);
      });
  }
};
