import axios from 'axios';

const subscriptionEndPoint = process.env.REACT_APP_API_URI + 'subscription';

export const SubscriptionService = {
  async getSubscription(token) {
    return axios({
      method: 'get',
      url: subscriptionEndPoint,
      timeout: process.env.REACT_APP_API_REQUEST_TIMEOUT,
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response)
      .catch((error) => error.response);
  },

  async getCheckoutSessionUrl(token, payload) {
    return axios({
      method: 'post',
      url: subscriptionEndPoint + '/checkout',
      timeout: process.env.REACT_APP_API_REQUEST_TIMEOUT,
      headers: {
        Authorization: token,
      },
      data: payload,
    })
      .then((response) => response)
      .catch((error) => error.response);
  },

  async getBillingUrl(token, payload) {
    return axios({
      method: 'post',
      url: subscriptionEndPoint + '/billing',
      timeout: process.env.REACT_APP_API_REQUEST_TIMEOUT,
      headers: {
        Authorization: token,
      },
      data: payload,
    })
      .then((response) => response)
      .catch((error) => error.response);
  },

  checkSuccessfulSubscription(token, payload) {
    return axios({
      method: 'post',
      url: subscriptionEndPoint + '/success-subscription',
      timeout: process.env.REACT_APP_API_REQUEST_TIMEOUT,
      headers: {
        Authorization: token,
      },
      data: payload,
    });
  },
};
